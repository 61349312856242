import React from "react";
import { truncate } from "lodash";
import classNames from "classnames";
import unescapeXMLCharacters from "src/utils/unescapeXMLCharacters";
import RecordThumbnail from "./RecordThumbnail";
import RecordLink from "./RecordLink";

const VerticalRecordCard = ({ editOverlay, children, ...props }) => {
  const { id, title, source, link, externalLink, className, category } = props;
  const truncatedTitle = truncate(unescapeXMLCharacters(title), {
    length: 100,
  });

  return (
    <div className={classNames("record-card", className)} id={`card_${id}`}>
      {editOverlay}
      <RecordLink
        isExternalLink={externalLink}
        link={link}
        className="record-card__link height-100"
      >
        <RecordThumbnail {...props} />
        <div className="record-card__body">
          <h2 className="record-card__title text-truncate-3">{truncatedTitle}</h2>
          <p className="record-card__subtitle text-truncate-2">
            { category.includes('Stories') && 'Created by '}
            {source}
          
          </p>
        </div>
      </RecordLink>
      {children ? <div className="record-card__footer">{children}</div> : null}
    </div>
  );
};

export default VerticalRecordCard;
