import React from "react";
import ReactModal from "react-modal";

if (process.env.NODE_ENV != "test") {
  ReactModal.setAppElement("#root");
}

const Modal = (props) => {
  const OverlayElement = function (props, contentElement) {
    return (
      <div className={`modal ${this.modalClassName}`}>
        <div {...props}>
          <div className="modal__dialog">{contentElement}</div>
        </div>
      </div>
    );
  };

  const ContentElement = function (props, children) {
    return (
      <div {...props}>
        <div className="modal__body">
          <div className="generic-modal__header">
            <h2>{this.title}</h2>
            {this.shouldHaveCloseButton !== false && (
              <button
                onClick={this.onRequestClose}
                className="modal__close"
                tabIndex="0"
              >
                ×
              </button>
            )}
          </div>
          <hr />
          {children}
        </div>
      </div>
    );
  };

  return (
    <ReactModal
      {...props}
      bodyOpenClassName="modal-open"
      modalClassName={props.className}
      overlayClassName="modal__overlay"
      className="modal__content"
      closeTimeoutMS={400}
      overlayElement={OverlayElement}
      contentElement={ContentElement}
    >
      {props.children}
    </ReactModal>
  );
};

export default Modal;
