import React from "react";
import Slot from "./Slot";
import AddTextArea from "./AddTextArea";
import classNames from "classnames";
import { map, get, first, last, chunk, isEmpty } from "lodash";

export const Row = ({ contents, findBlockIndex, id, ...storyProps }) => {
  const hasLargeBlock = get(first(contents), "meta.alignMode", 0) == 1;

  const dropClasses = classNames("grid-x", "row", {
    "row--drop":
      (storyProps.activeSlot.rowId === id &&
        storyProps.activeSlot.blockSize === "large" &&
        storyProps.activeSlot.adjacentRowId === "") ||
      (storyProps.activeSlot.adjacentRowId === id &&
        storyProps.activeSlot.blockSize === "large"),
  });

  const displayTextBlock = () => {
    const containsTextBlock = get(first(contents), "type", "embed") == "text";

    const rows = chunk(storyProps.blocks, 3);
    const nextRowContainsTextBlock =
      get(first(rows[id + 1]), "type", "embed") == "text";

    return !(containsTextBlock || nextRowContainsTextBlock);
  };

  return (
    <div className={dropClasses} data-equalizer>
      {map(contents, (content, index) => {
        return (
          <Slot
            content={content}
            findBlockIndex={findBlockIndex}
            rowId={id}
            id={index}
            key={`slot-${id}-${index}`}
            hasLargeBlock={hasLargeBlock}
            {...storyProps}
          />
        );
      })}
      {storyProps.editable && displayTextBlock() && (
        <AddTextArea
          index={last(contents).position}
          disabled={!isEmpty(storyProps.activeTextItem)}
        />
      )}
    </div>
  );
};
