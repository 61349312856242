import React, { useState } from "react";
import Modal from "src/components/Modal";
import { csrfParam, csrfToken } from "src/utils/request";
import { useDispatch } from "react-redux";
import {
  setEditingUserImage,
  openEditUserImageModal,
} from "src/features/EditUserImageSlice";

const EditOverlay = ({ id, userImage, thumbnailImageUrl, title }) => {
  const dispatch = useDispatch();
  let [deleteModalOpened, setOpenedDeleteModal] = useState(false);

  const handleEditClick = () => {
    dispatch(setEditingUserImage(id));
    dispatch(openEditUserImageModal());
  };

  return (
    <>
      <div className="record-card__edit-overlay">
        <button
          className="record-card__edit-overlay__link"
          onClick={handleEditClick}
        >
          <i
            className="fa fa-pencil fa-2x"
            role="presentation"
            aria-hidden="true"
          ></i>
        </button>

        <button
          className="record-card__edit-overlay__link"
          onClick={() => setOpenedDeleteModal(true)}
        >
          <i
            className="fa fa-trash-o trash-button-embed fa-2x"
            aria-hidden="true"
            role="presentation"
          ></i>
        </button>
      </div>

      <Modal
        isOpen={deleteModalOpened}
        onRequestClose={() => setOpenedDeleteModal(false)}
        className="generic-modal"
        title="Delete this image"
      >
        <div className="generic-modal__content">
          <img src={`${thumbnailImageUrl}&resize=368%253E`} alt={title} />

          <p>
            Are you sure you want to delete this image from DigitalNZ? It will
            be removed from any story it has been added to.
          </p>
        </div>
        <hr />
        <div className="generic-modal__actions">
          <form method="post" action={`/user_images/${userImage.id}`}>
            <input type="hidden" name="_method" value="delete" />
            <input type="hidden" name={csrfParam()} value={csrfToken()} />
            <button
              role="button"
              type="button"
              className="button clear"
              onClick={() => setOpenedDeleteModal(false)}
            >
              Cancel
            </button>

            <button type="submit" className="button">
              Delete
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default EditOverlay;
