import React, { useState } from "react";
import { useDrag } from "react-dnd";
import { TextBlock } from "./TextBlock";
import { EmbedBlock } from "./EmbedBlock";
import Overlay from "./Overlay";
import BlockWrapper from "./BlockWrapper";

import inBrowser from "src/utils/inBrowser";

const typeMappings = {
  text: TextBlock,
  embed: EmbedBlock,
};

const Block = (props) => {
  const [active, setActive] = useState(false);

  const {
    type,
    subType,
    content,
    meta,
    id,
    addBlock,
    removeBlock,
    findBlockIndex,
    editing,
    activeTextItem,
    modifiable,
    viewMode,
    user,
    activeRecordItem,
    storyTextBlockCount,
    privacy,
    openPrivacyModal,
    isOver,
    belongsToUser,
    imageId,
    dispatch,
    position,
    storyId,
    rowId,
    slotId,
  } = props;

  const BlockComponent = typeMappings[type];

  const mode = editing ? "editing" : viewMode;

  const doneEditingAction = () => {
    setActive(false);
  };

  const [{ monitor }, dragRef] = useDrag(
    () => ({
      type: "SLOT",
      item: {
        id,
        position,
        slotId,
        rowId,
        meta: { alignMode: props.meta.alignMode },
      },
      collect: (monitor) => ({ monitor }),
      canDrag: () => !props.activeRecordItem,
    }),
    [props]
  );

  const blockProps = {
    id,
    type,
    content,
    meta,
    editing,
    active,
    addBlock,
    activeTextItem,
    findBlockIndex,
    removeBlock,
    subType,
    mode,
    doneEditing: doneEditingAction,
    Overlay: Overlay,
    user: user,
    activeRecordItem,
    privacy,
    openPrivacyModal,
    storyTextBlockCount,
    modifiable,
    isOver,
    belongsToUser,
    imageId,
    dispatch,
    position,
    storyId,
    monitor,
  };

  let actionProps = {
    onFocus: () => setActive(true),
    onBlur: () => setActive(false),
  };

  // There is an open bug in Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=1189486
  // that prevents a text box from being clicked into if it is inside of a HTML5 draggable element.
  // To get around this we do not initialize the dragRef when there is an activeRecordItem.
  // An activeRecordItem is where a story record has the edit pane toggled open.

  return (
    <BlockWrapper
      BlockComponent={BlockComponent}
      dragRef={
        inBrowser() && !editing && modifiable && activeRecordItem == null
          ? dragRef
          : null
      }
      actionProps={actionProps}
      blockProps={blockProps}
    />
  );
};

export default Block;
