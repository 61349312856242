import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "src/components/Modal";

import { pickBy, keys, filter } from "lodash";

import inBrowser from "src/utils/inBrowser";
import { request } from "src/utils/request";
import InputCounter from "src/utils/inputCounter";

class ReportItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recaptchaSuccess: false,
      canReport: false,
      additionalFields: false,
      report: {
        offensive: false,
        culturallyInsensitive: false,
        spam: false,
        copyrightViolation: false,
        recaptchaHash: null,
        reason: "",
        email: "",
      },
      reportItemModal: false,
    };

    if (process.env.RAILS_ENV == "test") {
      this.state.recaptchaSuccess = true;
    }

    this.handleSuccessfulRecaptcha = this.handleSuccessfulRecaptcha.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportItemModalButtonClick =
      this.handleReportItemModalButtonClick.bind(this);
    this.submitReportRecord = this.submitReportRecord.bind(this);
    this.additionalFields = this.additionalFields.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  handleReportItemModalButtonClick(event) {
    event.preventDefault();

    this.setState({
      reportItemModal: true,
    });
    return;
  }

  submitReportRecord(event) {
    event.preventDefault();

    // disable report item submit button
    this.setState({ canReport: false });

    // toggle spinner on
    this.toggleSpinner();

    const params = {
      url: this.reportRecordRoute(),
      body: this.state.report,
      options: { method: "post" },
    };

    request(params)
      .then((resp) => {
        if (resp.ok) return resp.text();
      })
      .then((data) => {
        // add success message to body
        $(document.body).prepend(data);

        // reset component state
        this.resetState();

        // reset Recaptcha input
        if (typeof window.grecaptcha !== "undefined") window.grecaptcha.reset();

        // reset checkboxes
        $('.report-item-modal__input-group input[type="checkbox"]').prop(
          "checked",
          false
        );

        // toggle spinner off
        this.toggleSpinner();
      })
      .bind(this);
  }

  toggleSpinner() {
    const spinner = $("i.fa-spin");

    if (spinner.hasClass("hide")) spinner.removeClass("hide");
    else spinner.addClass("hide");

    return;
  }

  handleChange(event) {
    const report = this.state.report;

    if (event.target.id == "reason" || event.target.id == "email") {
      report[event.target.id] = event.target.value;
    } else {
      const reportOptionKey = event.target.value;
      report[reportOptionKey] = event.target.checked;
    }

    // Determine which option has been checked
    // We only want to show additional fields if any field except spam
    // has been ticked.
    let options = keys(
      pickBy(report, (item) => {
        return item == true;
      })
    );

    options = filter(options, (option) => {
      return option != "spam";
    });

    // If no options have been selected, remove the reason and email values.
    if (options.length == 0) {
      report["reason"] = "";
      report["email"] = "";
    }

    const state = {
      canReport: this.canReport(),
      report: report,
      additionalFields: options.length >= 1,
    };

    this.setState(state);
  }

  handleSuccessfulRecaptcha(authHash) {
    const report = this.state.report;
    report.authHash = authHash;

    // update the recaptcha status
    // before checking if report can be submitted
    // also add recaptcha hash to report
    this.setState({
      recaptchaSuccess: true,
      report: report,
    });

    // recaptcha state updated
    // now, determine whether report can be submitted
    this.setState({
      canReport: Object.values(this.state.report).includes(true) 
    });
  }

  reportRecordRoute() {
    return `/records/${this.props.recordId}/report`;
  }

  resetState() {
    this.setState({
      recaptchaSuccess: false,
      canReport: false,
      additionalFields: false,
      report: {
        offensive: false,
        culturallyInsensitive: false,
        spam: false,
        copyrightViolation: false,
        reason: "",
        email: "",
      },
      reportItemModal: false,
    });
  }

  canReport() {
    return (
      this.state.recaptchaSuccess &&
      Object.values(this.state.report).includes(true)
    );
  }

  additionalFields() {
    return (
      <div>
        <div className="report-item-modal__input-group">
          <label htmlFor="reason">
            Reason{" "}
            <span className="report-item-modal__input-group__label-help">
              (optional)
            </span>
            <span className="input-counter">120</span>
          </label>
          <textarea
            value={this.state.report.reason}
            id="reason"
            onChange={this.handleChange}
            maxLength="120"
            rows="2"
          ></textarea>
        </div>

        <div className="report-item-modal__input-group">
          <label htmlFor="email">
            Email address{" "}
            <span className="report-item-modal__input-group__label-help">
              (optional)
            </span>
          </label>
          <input
            type="email"
            value={this.state.report.email}
            id="email"
            onChange={this.handleChange}
          />

          <i className="report-item-modal__input-group__description">
            This is used in case we need to get further information.
          </i>
        </div>
      </div>
    );
  }

  render() {
    const recaptcha = (
      <ReCAPTCHA
        className="recaptcha"
        sitekey={this.props.recaptchaSiteKey}
        onChange={this.handleSuccessfulRecaptcha}
      />
    );

    return (
      <>
        <a
          href="#"
          className="record-side-info__link"
          onClick={this.handleReportItemModalButtonClick}
        >
          Report this item
        </a>
        <Modal
          isOpen={this.state.reportItemModal}
          onRequestClose={this.resetState}
          className="generic-modal report-item-modal"
          title="Report this item"
        >
          <form method="post" onSubmit={this.submitReportRecord}>
            <div className="generic-modal__content modal__mobile-scroll">
              <p>
                If you believe this item breaches our{" "}
                <a
                  className="report-item-modal__link"
                  href="/about/terms-of-use"
                  target="_blank"
                >
                  terms of use
                </a>
                , please let us know why and we will determine what action needs
                to be taken.
              </p>
              <strong>Please tick all that apply:</strong>

              <div className="report-item-modal__options">
                <div className="report-item-modal__input-group">
                  <input
                    type="checkbox"
                    value="offensive"
                    id="offensive"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="offensive">It is offensive or obscene</label>
                </div>
                <div className="report-item-modal__input-group">
                  <input
                    type="checkbox"
                    value="culturallyInsensitive"
                    id="culturally-insensitive"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="culturally-insensitive">
                    It is culturally insensitive
                  </label>
                </div>
                <div className="report-item-modal__input-group">
                  <input
                    type="checkbox"
                    value="spam"
                    id="spam"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="spam">It is spam</label>
                </div>
                <div className="report-item-modal__input-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      value="copyrightViolation"
                      id="copyright-violation"
                      onChange={this.handleChange}
                    />
                    It infringes someone else’s copyright
                  </label>
                </div>
                <div className="report-item-modal__input-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      value="privacyViolation"
                      id="privacy-violation"
                      onChange={this.handleChange}
                    />
                    It breaches someone else's privacy
                  </label>
                </div>
              </div>

              {this.state.additionalFields && this.additionalFields()}
              {this.state.additionalFields && InputCounter.init()}

              {inBrowser() && process.env.RAILS_ENV != "test" && recaptcha}
            </div>

            <hr />
            <div className="generic-modal__actions">
              <button
                className="button clear"
                type="button"
                value="Cancel"
                onClick={() => this.resetState()}
              >
                Cancel
              </button>
              <button
                className="button"
                type="submit"
                disabled={!this.state.canReport}
              >
                <i
                  className="fa fa-circle-o-notch fa-spin start hide"
                  aria-hidden="true"
                ></i>
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </>
    );
  }
}

export default ReportItem;
