import { addItemToStory, removeItem } from "src/features/StoriesSlice";
import { blockAdd } from "src/features/ItemsSlice";
import {
  addProcessingRecord,
  removeProcessingRecord,
} from "src/features/AddToStorySlice";
import { find, some } from "lodash";

/**
 * @param {Object} story
 * @param {Integer} recordId
 * @returns true if the record is present in the story, false otherwise
 */
export const isRecordInStory = (story, recordId) => {
  return some(story.recordIds, { recordId: recordId });
};

/**
 * add and remove items to stories depending on the storiesMap
 *
 * @param {Function} dispatch function to dispatch actions to redux
 * @param {Array} stories every stories linked to the current user
 * @param {Object} storiesMap in the form: {storyId1: true, storyId2: false}
 *   true "meaning add to story", false meaning "remove to story"
 * @param {Object} record to add or remove to the stories
 */
const addAndRemoveToStories = async ({
  record,
  storiesMap,
  stories,
  dispatch,
}) => {
  dispatch(addProcessingRecord(record.id));
  for (let storyId in storiesMap) {
    const story = find(stories, ["id", storyId]);

    if (storiesMap[storyId] && !isRecordInStory(story, record.id)) {
      const response = await dispatch(
        blockAdd({
          storyId: storyId,
          type: "embed",
          subType: "record",
          content: record,
        })
      );
      if (response.payload) {
        dispatch(addItemToStory({ storyId, storyItem: response.payload }));
      }
    } else if (!storiesMap[storyId] && isRecordInStory(story, record.id)) {
      const blockId = find(story.recordIds, {
        recordId: record.id,
      }).storyItemId;
      await dispatch(
        removeItem({
          storyId: storyId,
          blockId: blockId,
          recordId: record.recordId,
        })
      );
    }
  }
  dispatch(removeProcessingRecord(record.id));
};

export default addAndRemoveToStories;
