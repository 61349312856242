import React from "react";
import usageText from "./text";
import MediaInRecordPage from "src/components/MediaInRecordPage";

import shareImage from "images/share.png";
import noShareImage from "images/no-share.png";
import modifyImage from "images/modify.png";
import noModifyImage from "images/no-modify.png";
import commercialUseImage from "images/commercial-use.png";
import noCommercialUseImage from "images/no-commercial-use.png";
import unknownShareImage from "images/unknown-share.png";
import unknownModifyImage from "images/unknown-modify.png";
import unknownCommercialUseImage from "images/unknown-commercial-use.png";
import researchImage from "images/research.png";

const Usage = ({ usage, displayContentPartner, sourceUrl, userImage }) => {
  if (!usage) return null;

  let shareStatus = "RESTRICTED";
  let modifyStatus = "RESTRICTED";
  let commercialStatus = "RESTRICTED";
  let shareIcon = noShareImage;
  let modifyIcon = noModifyImage;
  let commercialIcon = noCommercialUseImage;

  if (usage.includes("Share")) {
    shareStatus = "OPEN";
    shareIcon = shareImage;
  }

  if (usage.includes("Modify")) {
    modifyStatus = "OPEN";
    modifyIcon = modifyImage;
  }

  if (usage.includes("Use commercially")) {
    commercialStatus = "OPEN";
    commercialIcon = commercialUseImage;
  }

  if (usage.includes("Unknown")) {
    shareStatus = "UNKNOWN";
    modifyStatus = "UNKNOWN";
    commercialStatus = "UNKNOWN";
    shareIcon = unknownShareImage;
    modifyIcon = unknownModifyImage;
    commercialIcon = unknownCommercialUseImage;
  }

  return (
    <div className="record-section" aria-describedby="record-usage-label">
      <h3 className="record-section__header">What can I do with this item?</h3>

      {!!userImage || (
        <p>
          <a href={sourceUrl} target="_blank" className="external-link">
            You must always check with {displayContentPartner}
          </a>{" "}
          to confirm the specific terms of use, but this is our understanding:
        </p>
      )}

      <MediaInRecordPage
        img_src={researchImage}
        img_alt="Research icon"
        title="Non-infringing use"
      >
        <p>
          <a
            href="http://www.legislation.govt.nz/act/public/1994/0143/117.0/DLM345634.html"
            target="_blank"
            className="external-link"
          >
            NZ Copyright law
          </a>{" "}
          does not prevent every use of a copyright work. You should consider
          what you can and cannot do with a copyright work.
        </p>
      </MediaInRecordPage>

      <MediaInRecordPage
        img_src={shareIcon}
        img_alt={`${usageText[shareStatus].shareStatus} icon`}
        title={usageText[shareStatus].shareStatus}
      >
        <p>{usageText[shareStatus].shareMessage}</p>
      </MediaInRecordPage>

      <MediaInRecordPage
        img_src={modifyIcon}
        img_alt={`${usageText[modifyStatus].modifyStatus} icon`}
        title={usageText[modifyStatus].modifyStatus}
      >
        <p>{usageText[modifyStatus].modifyMessage}</p>
      </MediaInRecordPage>

      <MediaInRecordPage
        img_src={commercialIcon}
        img_alt={`${usageText[commercialStatus].commercialStatus} icon`}
        title={usageText[commercialStatus].commercialStatus}
      >
        <p>{usageText[commercialStatus].commercialMessage}</p>
      </MediaInRecordPage>
    </div>
  );
};

export default Usage;
