import React, { useEffect, useState } from "react";
import { truncate } from "lodash";
import { snakeToCamelCase } from "src/utils/keyConverters";
import RecordCard from "src/components/RecordCard/RecordCard";
import { request } from "src/utils/request";

const MoreLikeThisItems = ({ recordId }) => {
  let [items, setItems] = useState(false);

  useEffect(() => {
    request({ url: `/records/${recordId}/more_like_this.json` })
      .then((response) => response.json())
      .then((itemsAsJson) => setItems(snakeToCamelCase(itemsAsJson)));
  }, [recordId]);

  let body = null;
  if (items === false) {
    body = <p>Loading...</p>;
  } else if (items.length === 0) {
    body = <p>No related items found.</p>;
  } else if (items.length) {
    body = (
      <div className="grid-x grid-margin-x">
        {items.map((item) => (
          <div
            className="cell small-12 medium-6 large-3 margin-bottom-0-5"
            key={item.id}
          >
            <RecordCard
              id={item.id}
              className="record-card--no-margin"
              imageUrl={item.thumbnailImageUrl}
              title={truncate(item.title, { length: 70 })}
              source={item.displayContentPartner}
              category={item.category}
              externalLink={true}
              link={`/records/${item.id}`}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="cell record-section">
      <h3 className="record-section__header record-section__header--no-border">
        Related items
      </h3>
      {body}
    </div>
  );
};

export default MoreLikeThisItems;
