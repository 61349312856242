import React, { Component } from "react";
import { bindAll, truncate } from "lodash";
import classnames from "classnames";

const READ_MORE_LABEL = "Read more";
const READ_LESS_LABEL = "Read less";

class ReadMore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      buttonLabel: READ_MORE_LABEL,
    };

    bindAll(this, "expand");
  }

  expand() {
    const isExpanded = !this.state.isExpanded;

    this.setState({
      isExpanded: isExpanded,
      buttonLabel: isExpanded ? READ_LESS_LABEL : READ_MORE_LABEL,
    });
  }

  render() {
    const { contentClass, maxCharacters, maxSentences, children } = this.props;
    const sentences = children.split(/\.\s/);
    let truncatedContent = sentences.slice(0, maxSentences).join(". ");
    truncatedContent = truncate(truncatedContent, { length: maxCharacters });
    const isTruncated =
      children.length > maxCharacters || sentences.length > maxSentences;

    return (
      <>
        <p
          className={classnames(contentClass, {
            [`${contentClass}--truncated`]: isTruncated,
          })}
        >
          {this.state.isExpanded ? children : truncatedContent}
        </p>

        {isTruncated && (
          <button className={"button clear padding-0"} onClick={this.expand}>
            <i
              className={classnames("fa start", {
                "fa-plus": !this.state.isExpanded,
                "fa-minus": this.state.isExpanded,
              })}
            ></i>
            {this.state.buttonLabel}
          </button>
        )}
      </>
    );
  }
}

export default ReadMore;
