import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchMoreSearchResults, selectSearch } from "src/features/SearchSlice";
import truncate from "lodash/truncate";
import Parameterize from "parameterize";
import findGetParameter from "src/utils/findGetParameter";
import generateSearchParams from "src/utils/generateSearchParams";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import totalPages from 'src/utils/totalPages';

const RecordPageNav = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSelector(selectSearch);

  let [direction, setDirection] = useState();

  useEffect(() => {
    const currentRecord = props.searchRecords[
      props.searchRecords.findIndex((record) => record.id === props.record.id)
    ];

    // If the current record is not in the search records
    // We have loaded a new page in the background and need to navigate to a new page.
    if(!currentRecord) {
      if(direction == 'forward') {
        const nextRecord = props.searchRecords[0];

        navigate(`/records/${nextRecord.recordId}/${truncate(
          Parameterize(nextRecord.title),
          { length: 75, omission: "" }
        )}`);
      } else {
        const previousRecord = props.searchRecords[props.searchRecords.length - 1];

        navigate(`/records/${previousRecord.recordId}/${truncate(
          Parameterize(previousRecord.title),
          { length: 75, omission: "" }
        )}`);
      }
    }
  }, [props.searchRecords, direction, navigate, props.record.id]);

  const isFirstRecord = () => {
    const results = props.searchRecords;
    const currentRecord = props.record;
    const firstRecord = results[0]

    if(results == undefined) {
      return false;
    }

    return(firstRecord.id == currentRecord.id);
  }

  const isLastRecord = () => {
    const results = props.searchRecords;
    const currentRecord = props.record; 

    if(results == undefined) {
      return false;
    }

    return(results.length - 1 == results.findIndex((record) => record.id === currentRecord.id))
  }

  const fetchResults = (direction) => {
    // This conditional prevents an infinite loop.
    if (props.page * props.per_page < props.total_pages) {
      let page;

      if(isFirstRecord() && direction == 'back') {
        page = props.page - 1;
        setDirection('back');
        dispatch(fetchMoreSearchResults(page));
      }

      if(isLastRecord() && direction == 'forward') {
        page = props.page + 1;
        setDirection('forward');
        dispatch(fetchMoreSearchResults(page));
      }
    }
  };

  const nextSearchResult = () => {
    const results = props.searchRecords;
    const currentRecord = props.record;
    const nextRecord =
      results[
        results.findIndex((record) => record.id === currentRecord.id) + 1
      ];

    if (nextRecord && !props.loading) {
      return `/records/${nextRecord.recordId}/${truncate(
        Parameterize(nextRecord.title),
        { length: 75, omission: "" }
      )}`;
    }

    return "#";
  };

  const previousSearchResult = () => {
    const results = props.searchRecords;
    const currentRecord = props.record;
    const previousRecord =
      results[
        results.findIndex((record) => record.id === currentRecord.id) - 1
      ];

    if (previousRecord && !props.loading) {
      return `/records/${previousRecord.recordId}/${truncate(
        Parameterize(previousRecord.title),
        { length: 75, omission: "" }
      )}`;
    }

    return "#";
  };

  const nextLinkDisabled = () => {
    return props.page == totalPages(search) && isLastRecord();
  }

  const previousLinkDisabled = () => {
    return props.page == 1 && isFirstRecord();
  }

  const searchParams = generateSearchParams(
    props.searchValue,
    props.filters,
    props.activeTab,
    props.page
  );
  const location = useLocation();
  const storyId = findGetParameter("from-story", location.search);
  const displayNav = storyId !== null || props.fromSearchResults;
  const record = props.record;

  let navParams = {
    backPath: `/stories/${storyId}`,
    backText: " Back to story",
    fromRouter: false,
    previousSearchResult: null,
    nextSearchResult: null,
    recordId: null,
    display: displayNav,
  };
  if (props.fromSearchResults) {
    navParams = {
      backPath: `/records?${$.param(searchParams)}#card_${record.recordId}`,
      backText: " Back to results",
      fromRouter: true,
      previousSearchResult: previousSearchResult(),
      nextSearchResult: nextSearchResult(),
      recordId: record.recordId,
      display: true,
    };
  }

  let Component = Link,
    navProp = { to: navParams.backPath };
  if (!navParams.fromRouter) {
    Component = "a";
  }

  return (
    <div className={classNames("record-nav", { hide: !navParams.display })}>
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-margin-y align-middle">
          <div className="cell medium-shrink small-12">
            <Component className="button hollow white" {...navProp}>
              <i className="fa fa-chevron-left start"></i>
              {navParams.backText}
            </Component>
          </div>
          <div className="medium-auto"></div>
          {(navParams.previousSearchResult != null ||
            navParams.nextSearchResult != null) && (
            <>
              <div className="cell medium-shrink small-6">
                <Link
                  className={classNames( "button white clear padding-0 text-nowrap", { disabled: previousLinkDisabled() })}
                  to={navParams.previousSearchResult}
                  onClick={ () => { fetchResults('back') }}
                >
                  <i className="fa fa-chevron-left start"></i>Previous record
                </Link>
              </div>
              <div className="cell medium-shrink small-6 text-right">
                <Link
                  className={classNames( "button white clear padding-0 text-nowrap", { disabled: nextLinkDisabled() })}
                  to={navParams.nextSearchResult}
                  onClick={ () => { fetchResults('forward') }}
                >
                  Next record<i className="fa fa-chevron-right end"></i>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecordPageNav;
