import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreSearchResults, selectSearch } from "src/features/SearchSlice";
import generateSearchParams from "src/utils/generateSearchParams";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { range, map } from "lodash";
import totalPages from "src/utils/totalPages";

const Pagination = () => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);

  const handlePageClick = (page) => {
    dispatch(fetchMoreSearchResults(page));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const searchParams = (page) => {
    if (page == 0) {
      page = 1;
    }

    return generateSearchParams(
      search.query,
      search.filters,
      search.activeTab,
      page
    );
  };

  const pages = () => {
    let startingPage = 1;
    let endingPage = totalPages(search);

    if (endingPage > 9) {
      endingPage = 9;
    }

    if (startingPage + 6 <= search.page) {
      startingPage = search.page - 4;

      if (search.page + 4 < totalPages(search)) {
        endingPage = search.page + 4;
      } else {
        endingPage = totalPages(search);
      }
    }

    if (endingPage >= 100) {
      endingPage = 100;
    }

    // + 1 here as lodashes range isn't inclusive..
    return range(startingPage, endingPage + 1);
  };

  const showPreviousOffsetIndiciation = () => {
    return search.page > 6 && totalPages(search) > 9;
  };

  const showNextOffsetIndication = () => {
    return totalPages(search) > 9 && search.page < totalPages(search) - 4;
  };

  const desktopPagination = () => {
    return (
      <ul className="text-center pagination show-for-medium">
        <li>
          <Link
            to={`/records?${$.param(searchParams(search.page - 1))}`}
            onClick={() => handlePageClick(search.page - 1)}
            className={classNames({ disabled: search.page - 1 === 0 })}
          >
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
            Previous Page
          </Link>
        </li>

        {showPreviousOffsetIndiciation() && (
          <li>
            <span>...</span>
          </li>
        )}

        {map(pages(), (page) => {
          if (page == search.page) {
            return (
              <em key={page} className="current">
                {page}
              </em>
            );
          } else {
            return (
              <Link
                key={page}
                to={`/records?${$.param(searchParams(page))}`}
                onClick={() => handlePageClick(page)}
              >
                {page}
              </Link>
            );
          }
        })}

        {showNextOffsetIndication() && (
          <li>
            <span>...</span>
          </li>
        )}

        <li>
          <Link
            to={`/records?${$.param(searchParams(search.page + 1))}`}
            onClick={() => handlePageClick(search.page + 1)}
            className={classNames({
              disabled: search.page + 1 > totalPages(search),
            })}
          >
            Next Page
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </Link>
        </li>
      </ul>
    );
  };

  const mobilePagination = () => {
    return (
      <ul className="text-center pagination show-for-small-only">
        <li className="float-left">
          <Link
            to={`/records?${$.param(searchParams(search.page - 1))}`}
            onClick={() => handlePageClick(search.page - 1)}
            className={classNames({ disabled: search.page - 1 === 0 })}
          >
            Previous
          </Link>
        </li>
        <li className="pagination__counter">
          {search.page} / {totalPages(search)}{" "}
        </li>

        <li className="float-right">
          <Link
            to={`/records?${$.param(searchParams(search.page + 1))}`}
            onClick={() => handlePageClick(search.page + 1)}
            className={classNames({
              disabled: search.page + 1 > totalPages(search),
            })}
          >
            Next
          </Link>
        </li>
      </ul>
    );
  };

  return (
    <>
      {totalPages(search) > 1 && (
        <>
          {desktopPagination()}
          {mobilePagination()}
        </>
      )}
    </>
  );
};

export default Pagination;
