import React from "react";
import RichTextBlock from "./RichText/RichTextBlock";

const subtypeMappings = {
  "rich-text": RichTextBlock,
};

export const TextBlock = (props) => {
  const { subType } = props;
  const Component = subtypeMappings[subType];

  return <Component {...props} />;
};
