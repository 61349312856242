import React from "react";
import { useSelector } from "react-redux";
import { difference } from "lodash";

import generateDateRange from "src/utils/generateDateRange";

import { currentMetrics } from "src/features/Dashboard/MetricsSlice";
import { selectGlobalMetrics } from "src/features/Dashboard/GlobalMetricsSlice";
import { selectFilters } from "src/features/Dashboard/FiltersSlice";

const DownloadCsv = () => {
  const globalMetrics = useSelector(selectGlobalMetrics);
  const filters = useSelector(selectFilters);
  const metrics = useSelector((state) =>
    currentMetrics(state, filters.selectedFacet)
  );

  const allFacetsSelected = filters.selectedFacet === "all";
  const dates = generateDateRange(filters.startDate, filters.endDate);
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += `Date, NewItems, TotalItems, Interactions, Views&Impressions${
    allFacetsSelected ? ", PublicSets" : ""
  }\r\n`;

  let totalInteractions = 0;
  let totalViewImpressions = 0;
  let totalNewRecords = 0;

  const metricsDates = Object.keys(metrics);
  if (difference(dates, metricsDates).length !== 0) {
    return null;
  }

  dates.forEach((date) => {
    const newRecords = metrics[date].record
      ? metrics[date].record.total_new_records
      : "";
    const totalItems = metrics[date].record
      ? metrics[date].record.total_active_records
      : "";
    const interactions = metrics[date].view
      ? metrics[date].view.records_added_to_user_sets +
        metrics[date].view.records_added_to_user_stories +
        metrics[date].view.total_source_clickthroughs
      : "";
    const viewImpressions = metrics[date].view
      ? metrics[date].view.searches +
        metrics[date].view.record_page_views +
        metrics[date].view.user_set_views +
        metrics[date].view.user_story_views
      : "";
    const publicSets = globalMetrics[date]
      ? globalMetrics[date].total_public_sets
      : "";

    totalNewRecords += Number(newRecords);
    totalInteractions += Number(interactions);
    totalViewImpressions += Number(viewImpressions);

    csvContent += `${date}, ${newRecords}, ${totalItems}, ${interactions}, ${viewImpressions}${
      allFacetsSelected ? ", " + publicSets : ""
    }\r\n`;
  });

  csvContent += `TOTAL, ${totalNewRecords}, Not applicable, ${totalInteractions}, ${totalViewImpressions}${
    allFacetsSelected ? ", Not applicable" : ""
  }`;

  const encodedUri = encodeURI(csvContent);

  return (
    <a
      className="dashboard-download-csv button"
      href={encodedUri}
      download={`DigitalNZ metrics dashboard ${filters.selectedFacet} ${filters.startDate} ${filters.endDate}.csv`}
    >
      {`Download CSV (${(encodedUri.length / 1000).toFixed(1)}Kb) `}
      <i className="fa fa-download white" aria-hidden="true"></i>
    </a>
  );
};

export default DownloadCsv;
