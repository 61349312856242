import { combineReducers } from "@reduxjs/toolkit";

// entities
import items from "./features/ItemsSlice";
import user from "./features/UserSlice";
import stories from "./features/StoriesSlice";
import records from "./features/RecordsSlice";
import plugFeatures from "./features/PlugFeaturesSlice";

// ui
import appDetails from "./features/AppDetailsSlice";
import addToStory from "./features/AddToStorySlice";
import editUserImage from "./features/EditUserImageSlice";
import editStory from "./features/EditStorySlice";
import search from "./features/SearchSlice";
import dropdown from "./features/DropdownSlice";

// dashboard
import facets from "./features/Dashboard/FacetsSlice";
import filters from "./features/Dashboard/FiltersSlice";
import globalMetrics from "./features/Dashboard/GlobalMetricsSlice";
import metrics from "./features/Dashboard/MetricsSlice";

export const dashboardReducer = combineReducers({
  facets,
  filters,
  globalMetrics,
  metrics,
});

export const recordsReducer = combineReducers({
  entities: combineReducers({
    user,
    records,
    stories,
    items,
    plugFeatures,
  }),
  ui: combineReducers({
    appDetails,
    addToStory,
    editStory,
    editUserImage,
    search,
    dropdown,
  }),
});
