import React from "react";

const Login = () => {
  return (
    <>
      <div className="generic-modal__content">
        <p>
          You need to log in or sign up before you can add an item to a story.
        </p>
      </div>

      <hr />

      <div className="generic-modal__actions">
        <a className="button clear" href="/sign_up">
          Sign up
        </a>
        <a className="button" href="/login">
          Log in
        </a>
      </div>
    </>
  );
};

export default Login;
