import { createSlice } from "@reduxjs/toolkit";
import {
  addTextBlock,
  cancelAddTextBlock,
  blockFakeRemove,
  blockRemove,
  fetchStory,
  repositionApiBlocks,
  blockAdd,
  blockUpdate,
} from "./ItemsSlice";

const handleDoneEditing = (state, type) => {
  if (type === "text") {
    state.activeTextItem = null;
  }
  if (type === "embed") {
    state.activeRecordItem = null;
  }
};

const EditStorySlice = createSlice({
  name: "editStory",
  // These values are overritten by the redux presenter
  initialState: {
    activeTextItem: null,
    activeSlot: {
      rowId: "",
      slotId: "",
      blockSize: "",
      adjacentRowId: "",
    },
    activeRecordItem: null,
    storyIsLoading: false,
    storyError: false,
  },
  reducers: {
    editTextBlock: (state, action) => {
      state.activeTextItem = action.payload;
    },
    editCaptionAndNotes: (state, action) => {
      state.activeRecordItem = action.payload;
    },
    cancelEditCaptionAndNotes: (state, _action) => {
      state.activeRecordItem = null;
    },
    updateActiveSlot: (state, action) => {
      state.activeSlot = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTextBlock, (state, action) => {
        state.activeTextItem = `text-editor-${action.payload.position}`;
      })
      .addCase(cancelAddTextBlock, (state, _action) => {
        state.activeTextItem = null;
      })
      .addCase(blockAdd.fulfilled, (state, action) => {
        handleDoneEditing(state, action.payload.type);
      })
      .addCase(blockUpdate.fulfilled, (state, action) => {
        handleDoneEditing(state, action.payload.type);
      })
      .addCase(blockFakeRemove, (state, _action) => {
        state.activeRecordItem = null;
      })
      .addCase(blockRemove.fulfilled, (state, action) => {
        handleDoneEditing(state, action.payload.block.type);
      })
      .addCase(repositionApiBlocks.rejected, (state, _action) => {
        state.storyError = true;
      })
      .addCase(fetchStory.pending, (state, _action) => {
        state.storyIsLoading = true;
      })
      .addCase(fetchStory.fulfilled, (state, _action) => {
        state.storyIsLoading = false;
        state.activeTextItem = null;
        state.activeSlot = {
          rowId: "",
          slotId: "",
          blockSize: "",
          adjacentRowId: "",
        };
      });
  },
});

export const selectEditStory = (state) => state.ui.editStory;

const { actions, reducer } = EditStorySlice;
export const {
  editTextBlock,
  editCaptionAndNotes,
  cancelEditCaptionAndNotes,
  updateActiveSlot,
} = actions;

export default reducer;
