import React, { Component } from "react";
import StoryCheckboxes from "./StoryCheckboxes";
import { bindAll, some, isEqual } from "lodash";

class AddToStoryForm extends Component {
  constructor(props) {
    super(props);

    const record = props.record || {};
    const checkedStories = {};
    props.stories.forEach(({ id, recordIds }) => {
      checkedStories[id] = some(recordIds, { recordId: record.id });
    });

    this.state = {
      initialMap: checkedStories, // {id1: true, id2: false, id3: false...}
      storiesMap: Object.assign({}, checkedStories),
    };

    bindAll(this, "onStoryToggle", "handleSubmit");
  }

  onStoryToggle(storyId) {
    const storiesMap = this.state.storiesMap;
    storiesMap[storyId] = !storiesMap[storyId];
    this.setState({ storiesMap: storiesMap });
  }

  handleSubmit(event) {
    event.preventDefault();

    // only update stories where a checkbox has been changed
    const changedStoriesMap = {};
    for (let storyId in this.state.initialMap) {
      if (this.state.initialMap[storyId] != this.state.storiesMap[storyId]) {
        changedStoriesMap[storyId] = this.state.storiesMap[storyId];
      }
    }
    this.props.boundAddRemoveToStories(changedStoriesMap, this.props.record);
  }

  render() {
    const { stories, activateCreateMode, record, mode, toggleOpenCb, loading } =
      this.props;

    if (record == null) return null;

    return (
      <>
        <div className="generic-modal__content">
          <div className="grid-x grid-margin-x align-middle">
            {record.thumbnailImageUrl && (
              <div className="cell small-3">
                <img
                  src={record.thumbnailImageUrl}
                  alt={`Image of record "${record.title}"`}
                />
              </div>
            )}
            <div className="cell auto">
              <p>
                Add the item{" "}
                <em>
                  <strong>'{record.title}'</strong>
                </em>{" "}
                to a story:
              </p>
            </div>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="story-checkboxes">
            <StoryCheckboxes
              stories={stories}
              record={record}
              onStoryToggle={this.onStoryToggle}
              storiesMap={this.state.storiesMap}
            />

            <button
              className="button clear padding-0"
              aria-haspopup="true"
              aria-expanded={mode === "createStory" ? true : false}
              title="Click to create a new story"
              onClick={activateCreateMode}
            >
              <i className="fa fa-plus start"></i>
              Create new story
            </button>
          </div>

          <hr />
          <div className="generic-modal__actions">
            <button
              className="button clear"
              type="button"
              onClick={toggleOpenCb}
            >
              Cancel
            </button>
            <button
              className="button"
              type="submit"
              disabled={
                isEqual(this.state.initialMap, this.state.storiesMap) || loading
              }
            >
              {loading && (
                <i className="fa fa-circle-o-notch fa-spin start"></i>
              )}
              Done
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default AddToStoryForm;
