import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import bindAll from "lodash/bindAll";
import Modal from "src/components/Modal";

import Login from "./Login";
import CreateStoryForm from "./CreateStoryForm";
import AddToStoryForm from "./AddToStoryForm";

class AddToStoryModalFactory extends Component {
  constructor(props) {
    super(props);

    if (!props.loggedIn) this.state = { mode: "login" };
    else if (isEmpty(props.stories)) this.state = { mode: "createStory" };
    else this.state = { mode: "addToStory" };

    bindAll(this, "changeMode", "onAfterClose");
  }

  onAfterClose() {
    if (this.state.mode == "createStory") {
      this.setState({ mode: "addToStory" });
    }
    if ($(`#card_${this.props.record.id} [aria-haspopup="true"]`).length) {
      $(`#card_${this.props.record.id} [aria-haspopup="true"]`)[0].focus();
    }
  }

  changeMode(mode) {
    this.setState({ mode });
  }

  render() {
    const {
      stories,
      storyCreateCb,
      boundAddRemoveToStories,
      record,
      toggleOpenCb,
      loading,
      isOpened,
    } = this.props;

    const modeMap = {
      login: <Login />,
      createStory: (
        <CreateStoryForm
          loading={loading}
          storyCreateCb={storyCreateCb}
          toggleOpenCb={toggleOpenCb}
        />
      ),
      addToStory: (
        <AddToStoryForm
          stories={stories}
          activateCreateMode={this.changeMode.bind(null, "createStory")}
          boundAddRemoveToStories={boundAddRemoveToStories}
          record={record}
          toggleOpenCb={toggleOpenCb}
          loading={loading}
        />
      ),
    };

    return (
      <Modal
        isOpen={isOpened}
        onRequestClose={toggleOpenCb}
        onAfterClose={this.onAfterClose}
        className="generic-modal"
        title="Add to a story"
      >
        {modeMap[this.state.mode]}
      </Modal>
    );
  }
}

export default AddToStoryModalFactory;
