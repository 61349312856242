import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { selectSearch, switchLayoutClick } from "../features/SearchSlice";
import { useLocalStorage } from "src/utils/useLocalStorage";
import Analytics from "src/utils/Analytics";

const MasonryLayoutSwitch = () => {
  const [storageLayout, setLayout] = useLocalStorage(
    "searchResultsLayout",
    "grid"
  );
  let { layout } = useSelector(selectSearch);
  const dispatch = useDispatch();

  layout = layout || storageLayout;

  const handleClick = () => {
    layout = layout === "grid" ? "list" : "grid";
    setLayout(layout);
    dispatch(switchLayoutClick(layout));
    Analytics.event("switch_layout", "SearchSwitchLayout", layout);
  };

  return (
    <div className="layout-switch">
      <span className="layout-switch__text show-for-medium">View as: </span>
      <button className="layout-switch__button" onClick={handleClick}>
        <span
          className={classNames("layout-switch__icon", {
            "layout-switch__icon--active": layout !== "list",
          })}
        >
          <GridIcon />
        </span>
        <span
          className={classNames("layout-switch__icon", {
            "layout-switch__icon--active": layout === "list",
          })}
        >
          <ListIcon />
        </span>
      </button>
    </div>
  );
};

const GridIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99023 7.5H11.9902V11.5H7.99023V7.5ZM7.99023 13.5H11.9902V17.5H7.99023V13.5ZM13.9902 7.5H17.9902V11.5H13.9902V7.5ZM13.9902 13.5H17.9902V17.5H13.9902V13.5Z"
      fill="currentColor"
    />
  </svg>
);

const ListIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99023 6.68506H6.99023V8.68506H4.99023V6.68506ZM4.99023 11.6851H6.99023V13.6851H4.99023V11.6851ZM4.99023 16.6851H6.99023V18.6851H4.99023V16.6851ZM20.9902 8.68506V6.68506H9.01323V8.68506H19.7902H20.9902ZM8.99023 11.6851H20.9902V13.6851H8.99023V11.6851ZM8.99023 16.6851H20.9902V18.6851H8.99023V16.6851Z"
      fill="currentColor"
    />
  </svg>
);

export default MasonryLayoutSwitch;
