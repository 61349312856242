import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  closeMobileFilters,
  setMobileFiltersState,
  clearAllFacets,
  selectTab,
  selectSearch,
} from "src/features/SearchSlice";

const MobileFiltersIndex = ({ performSearch }) => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);

  const formatName = () => {
    if (search.activeTab == "All") {
      return "FORMAT (ALL)";
    } else {
      return search.activeTab;
    }
  };

  const filterName = (facet, onClick) => {
    const facetName = {
      date_range: 'DATE RANGE',
      content_partner: "CONTENT PARTNER",
      primary_collection: "COLLECTIONS",
      usage: "USAGE",
      decade: "DECADES",
    };

    const filtersApplied = search.filters
      .filter((b) => b.facet === facet)
      .map((a) => a.value);
    let tabName = facetName[facet];

    const filterClasses = classNames({
      "mobile-filters__filter": true,
      "mobile-filters__filter--active": filtersApplied.length > 0,
      "mobile-filters__filter--last": facet === "date_range",
      "mobile-filters__filter--decade-filter": facet === "decade",
    });

    if (filtersApplied.length > 0) {
      tabName = filtersApplied.shift();
      tabName += facetName[facet] === "DECADES" ? "s" : "";
      tabName =
        tabName.length >= 15 ? tabName.substring(0, 15) + "..." : tabName;
      const filtersCount = filtersApplied.length;
      if (filtersCount > 0) {
        tabName = `${tabName} + ${filtersCount} ${
          filtersCount === 1 ? "OTHER" : "OTHERS"
        }`;
      }
    }

    return (
      <FilterButton
        className={filterClasses}
        tabName={tabName}
        onClick={onClick}
      />
    );
  };

  const FilterButton = ({ className, onClick, tabName }) => (
    <button className={className} onClick={onClick}>
      {tabName}
      <i className="fa fa-chevron-right" aria-hidden="true"></i>
    </button>
  );

  return (
    <div className="mobile-filters">
      <div className="mobile-filters__nav">
        <button
          className="mobile-filters__nav__back"
          onClick={() => {
            dispatch(closeMobileFilters());
          }}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>Back
        </button>

        {(search.filters.length > 0 || search.activeTab != "All") && (
          <span
            className="mobile-filters__clear-all"
            onClick={() => {
              dispatch(clearAllFacets());
              dispatch(selectTab("All"));
            }}
          >
            Clear all filters
          </span>
        )}

        <a className="mobile-filters__nav__logo" href="/" />
      </div>

      <h1 className="mobile-filters__heading">Filters</h1>

      <div className="mobile-filters__wrapper">
        <FilterButton
          className="mobile-filters__filter mobile-filters__filter--active"
          tabName={formatName()}
          onClick={() => dispatch(setMobileFiltersState("format"))}
        />

        {filterName("content_partner", () =>
          dispatch(setMobileFiltersState("content-partners"))
        )}
        {filterName("primary_collection", () =>
          dispatch(setMobileFiltersState("collections"))
        )}
        {filterName("usage", () => dispatch(setMobileFiltersState("usage")))}

        {filterName("decade", () => dispatch(setMobileFiltersState("decades")))}
       
        {filterName("date_range", () => dispatch(setMobileFiltersState("date_range")))}
      </div>

      <button
        className="mobile-filters__button"
        onClick={() => {
          performSearch();
        }}
      >
        APPLY FILTERS
      </button>
    </div>
  );
};

export default MobileFiltersIndex;
