import React, { Component } from "react";
import numeral from "numeral";
import isString from "lodash/isString";

class MetricsBox extends Component {
  componentDidMount() {
    $(document).foundation();
  }

  render() {
    const { title, number, description, tooltip, notAvailable } = this.props;

    let metricInformation = (
      <div className="metric-details">
        <h1 className="number metric-box-number">
          <strong>{numeral(number).format("0,0")}</strong>
        </h1>
        <p className="description metric-box-description">{description}</p>
      </div>
    );

    if (notAvailable)
      metricInformation = (
        <p>
          These metrics are not available when you have a collection filter
          selected
        </p>
      );

    let metricHeader = <strong>{title}</strong>;
    if (isString(tooltip))
      metricHeader = (
        <strong
          data-tooltip
          aria-haspopup="true"
          data-disable-hover="false"
          className="has-tip top"
          title={tooltip}
        >
          {title}
        </strong>
      );

    return (
      <div className="metric-box">
        <h3 className="title">{metricHeader}</h3>
        {metricInformation}
      </div>
    );
  }
}

export default MetricsBox;
