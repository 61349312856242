import React from "react";
import Parameterize from "parameterize";
import AddToStoryButton from "src/components/AddToStoryButton/AddToStoryButton";
import { truncate } from "lodash";
import classNames from "classnames";

function withSearchResults(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }

    recordLink() {
      const { title, landingUrl, id, redirectToRecord } = this.props;

      let link = "";
      let externalLink = false;
      const isStory =
        landingUrl &&
        ((process.env.RAILS_ENV === "development" &&
          landingUrl.includes("http://www.dev/stories/")) ||
          landingUrl.includes("digitalnz.org/stories/"));

      if (isStory) {
        const storyId = landingUrl
          .match(RegExp("(?=stories).+"))
          .toString()
          .replace("stories", "");
        link = `/stories${storyId}`;
      } else {
        const truncatedTitle = truncate(Parameterize(title), {
          length: 75,
          omission: "",
        });
        link = `/records/${id}/${truncatedTitle}`;
      }

      // This is for the concepts page
      // Otherwise the record page will render inside of the concepts page
      if (redirectToRecord) {
        externalLink = true;
      }

      return { link, externalLink };
    }

    render() {
      const addToStoryButton = (
        <AddToStoryButton
          record={this.props}
          buttonClass={classNames({
            "button hollow ats-button-record--list ats-button-record":
              this.props.layout === "list",
            "clear width-100 ats-button-record": this.props.layout !== "list",
          })}
        />
      );
      return (
        <WrappedComponent
          {...this.recordLink()}
          {...this.props}
          children={addToStoryButton}
        />
      );
    }
  };
}

export default withSearchResults;
