import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { assign, isNil, map, reduce } from "lodash";
import { getEndDate, getSelectedFacet, getStartDate } from "./FiltersSlice";
import generateDateRange from "src/utils/generateDateRange";

export const fetchMetrics = createAsyncThunk(
  "metrics/fetchMetrics",
  async (payload, { rejectWithValue }) => {
    const { startDate, endDate, facet } = payload;

    const facetParam = encodeURIComponent(facet);
    return fetch(
      `/metrics?start_date=${startDate}&end_date=${endDate}&facet=${facetParam}`
    )
      .then((req) => req.json())
      .catch((e) => {
        return rejectWithValue(e.response);
      });
  }
);

const metricsSlice = createSlice({
  name: "metrics",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetrics.pending, (state, action) => {
        state[action.meta.arg.facet] = { loading: true };
      })
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        const facet = action.meta.arg.facet;
        const facetMetrics = action.payload.metrics[facet];

        state[facet].loading = false;
        for (const key of Object.keys(facetMetrics)) {
          state[facet][key] = facetMetrics[key];
        }
      })
      .addCase(fetchMetrics.rejected, (state, action) => {
        state[action.meta.arg.facet] = { loading: false };
      });
  },
});

export const selectMetrics = (state) => state.metrics;

export const currentMetrics = createSelector(
  [getSelectedFacet, selectMetrics],
  (selectedFacet, metrics) => metrics[selectedFacet]
);

export const selectedMetrics = createSelector(
  [getStartDate, getEndDate, currentMetrics],
  (startDate, endDate, currentMetrics_) => {
    const defaultState = { views: [], records: [] };
    if (isNil(currentMetrics_)) return defaultState;

    const activeDates = generateDateRange(startDate, endDate);
    const relevantMetrics = map(activeDates, (date) => {
      return { ...currentMetrics_[date], date };
    });

    return reduce(
      relevantMetrics,
      (acc, el) => {
        return assign(
          {},
          {
            views: [...acc.views, assign({}, el.view, { date: el.date })],
            records: [...acc.records, assign({}, el.record, { date: el.date })],
          }
        );
      },
      defaultState
    );
  }
);

export const selectedTotalItems = createSelector(
  [getEndDate, currentMetrics],
  (endDate, currentMetrics_) => {
    return currentMetrics_[endDate] && currentMetrics_[endDate].record
      ? currentMetrics_[endDate].record.total_active_records
      : "Unavailable";
  }
);

const { reducer } = metricsSlice;

export default reducer;
