import React from "react";
import StackBlock from "./StackBlock";

const modeMappings = {
  stack: StackBlock,
};

const DNZEmbedBlock = ({ mode, ...props }) => {
  const Component = modeMappings[mode];

  return <Component {...props} />;
};

export default DNZEmbedBlock;
