import { request } from "src/utils/request";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  largeBlock,
  onTheFarRight,
  inTheMiddle,
} from "src/components/StoriesPage/story/storyLogic/utility";
import storyBlocks from "src/components/StoriesPage/story/storyLogic/storyBlocks";
import { assign, find, findIndex, map, omitBy, pick, remove } from "lodash";

// Helper functions

export const fetchStory = createAsyncThunk(
  "items/fetchStoryStatus",
  async (storyId, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/api/stories/${storyId}.json`,
      }).then((req) => req.json());
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const blockAdd = createAsyncThunk(
  "items/blockAddStatus",
  async (payload) => {
    const { storyId, type, subType, content, position } = payload;

    const alignMode = type === "text" ? 1 : 0;
    const belongsToUser = !!content.belongsToUser;
    const imageId = content.imageId;

    let postPayload = {
      recordId: content.id,
      storyId,
      type,
      subType,
      content,
      meta: { alignMode },
      position,
      belongsToUser,
      imageId,
    };

    postPayload.meta = omitBy(postPayload.meta, (a) => a === "");

    const response = await request({
      url: `/api/stories/${storyId}/items`,
      body: { item: postPayload },
      options: { method: "POST" },
    })
      .then((req) => req.json())
      .then((json) => {
        return assign(json, { belongsToUser: belongsToUser });
      });

    return response;
  }
);

export const repositionApiBlocks = createAsyncThunk(
  "items/repositionApiBlocksStatus",
  async (payload) => {
    const { storyId, blocks } = payload;
    let changes = map(blocks, (item) => {
      return pick(item, ["id", "position"]);
    });

    changes = remove(changes, (item) => {
      return item.id != undefined;
    });

    const response = await request({
      url: `/api/stories/${storyId}/reposition`,
      body: {
        id: storyId,
        items: changes,
      },
      options: { method: "POST" },
    }).then((req) => req.json());

    return response;
  }
);

export const blockMetadataUpdate = createAsyncThunk(
  "items/blockMetadataUpdateStatus",
  async (payload, { getState }) => {
    const { storyId, fieldName, value, blockId } = payload;
    const blocks = selectBlocks(getState());

    const currentBlock = find(blocks, function (block) {
      return block.id == blockId;
    });

    const newValue = {
      [fieldName]: value,
    };

    const newMeta = { ...currentBlock.meta, ...newValue };

    const response = await request({
      url: `/api/stories/${storyId}/items/${blockId}`,
      body: { item: { meta: newMeta } },
      options: { method: "PATCH" },
    }).then((req) => req.json());

    return response;
  }
);

export const blockUpdate = createAsyncThunk(
  "items/blockUpdateStatus",
  async (payload) => {
    const { storyId, blockId } = payload;

    const itemPayload = payload;
    // Remove empty meta attributes
    if (itemPayload.meta) {
      itemPayload.meta = omitBy(itemPayload.meta, (a) => a === "");
    }
    const response = await request({
      url: `/api/stories/${storyId}/items/${blockId}`,
      body: { item: itemPayload },
      options: { method: "PATCH" },
    }).then((req) => req.json());

    return response;
  }
);

// TODO this is here because it relates to the story hash and removes the block from the story in that hash, rather than all stories. It is used on the story show page.
export const blockRemove = createAsyncThunk(
  "items/blockRemoveStatus",
  async (payload, { getState }) => {
    const { storyId, blockId } = payload;
    const block = selectBlockById(getState(), blockId);

    if (typeof blockId !== "undefined") {
      await request({
        url: `/api/stories/${storyId}/items/${blockId}`,
        body: {},
        options: { method: "DELETE" },
      }).then((req) => req.json());

      return { storyId, block };
    }
  }
);

const itemsSlice = createSlice({
  name: "items",
  // These values are overritten by the redux presenter
  initialState: {},
  reducers: {
    expandBlock: (state, action) => {
      const blockId = action.payload;
      const indexOfBlockToChange = findIndex(state.blocks, (block) => {
        return block.id == blockId;
      });

      state.blocks[indexOfBlockToChange].meta.alignMode = 1;
      let blocks = state.blocks;

      const changedBlock = state.blocks[indexOfBlockToChange];

      if (!onTheFarRight(changedBlock.position)) {
        let distance = 2;

        if (inTheMiddle(changedBlock.position)) {
          distance = 1;
        }

        blocks = map(state.blocks, (block, index) => {
          if (index > indexOfBlockToChange) {
            return {
              ...block,
              position: block.position + distance,
            };
          } else {
            return block;
          }
        });
      }

      state.blocks = storyBlocks(blocks);
    },

    compressBlock: (state, action) => {
      const blockId = action.payload;
      const indexOfBlockToChange = findIndex(state.blocks, (block) => {
        return block.id == blockId;
      });

      state.blocks[indexOfBlockToChange].meta.alignMode = 0;
      let emptyBlockOne;
      let emptyBlockTwo;

      emptyBlockOne = indexOfBlockToChange + 1;
      emptyBlockTwo = indexOfBlockToChange + 2;

      remove(state.blocks, (_block, index) => {
        return index == emptyBlockOne || index == emptyBlockTwo;
      });

      let blocks = map(state.blocks, (block, index) => {
        if (index > indexOfBlockToChange) {
          return {
            ...block,
            position: block.position - 2,
          };
        } else {
          return block;
        }
      });

      state.blocks = storyBlocks(blocks);
    },
    addTextBlock: (state, action) => {
      const { position } = action.payload;

      state.blocks.splice(position - 1, 0, {
        id: "text-editor-" + position,
        type: "text",
        subType: "rich-text",
        content: { value: "" },
        position: position,
        meta: {
          alignMode: 1,
        },
      });

      state.blocks = map(state.blocks, (block, index) => {
        if (index > position - 1) {
          return {
            ...block,
            position: block.position + 3,
          };
        } else {
          return block;
        }
      });

      state.blocks = storyBlocks(state.blocks);
    },
    cancelAddTextBlock: (state, action) => {
      const id = action.payload;
      if (!id.includes("text-editor-")) return;

      const blockIndex = state.blocks.findIndex((block) => block.id === id);
      state.blocks.splice(blockIndex, 3);
      let blocks = state.blocks.map((block, currentIndex) => {
        if (currentIndex >= blockIndex) {
          return { ...block, position: block.position - 3 };
        }
        return block;
      });
      state.blocks = storyBlocks(blocks);
    },
    updateStoryBlocks: (state, action) => {
      state.blocks = action.payload;
    },
    updateBlockData: (state, action) => {
      const { recordId, title } = action.payload;
      const blockToUpdateIndex = findIndex(state.blocks, (block) => {
        if (!block.content || !block.content.id) return false;
        return block.content.id.toString() === recordId.toString();
      });
      state.blocks[blockToUpdateIndex].content.title = title;
    },
    blockFakeRemove: (state, action) => {
      remove(state.blocks, function (block) {
        return block.id == action.payload;
      });
    },
    blockFakeAdd: (state, action) => {
      const { title, id } = action.payload;

      const fakeBlock = {
        belongsToUser: true,
        position: 1,
        content: {
          category: ["LoadingGif"],
          contentPartner: ["DigitalNZ"],
          title: title,
        },
        id: id,
        meta: { alignMode: 0, isCover: false },
        subType: "record",
        type: "embed",
      };

      let blocks = state.blocks;

      blocks = map(blocks, (block) => {
        return {
          ...block,
          position: block.position + 1,
        };
      });

      blocks.splice(0, 0, fakeBlock);
      state.blocks = storyBlocks(blocks);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(blockUpdate.fulfilled, (state, action) => {
        const newBlock = action.payload;

        let newBlocks = current(state.blocks);

        if (newBlock.meta.isCover == true) {
          newBlocks = map(state.blocks, function (block) {
            if (block.status !== "empty") {
              block.meta.isCover = false;
              return block;
            } else {
              return block;
            }
          });
        }

        newBlocks = map(newBlocks, function (block) {
          if (block.status != "empty" && block.id == newBlock.id) {
            return newBlock;
          } else {
            return block;
          }
        });

        state.blocks = newBlocks;
      })
      .addCase(blockAdd.fulfilled, (state, action) => {
        if (state.blocks != undefined) {
          const indexOfNewBlock = action.payload.position - 1;
          let blocks = state.blocks;

          blocks.splice(indexOfNewBlock, 1, action.payload);
          state.blocks = storyBlocks(blocks);
        }
      })
      .addCase(blockRemove.fulfilled, (state, action) => {
        const blockId = action.payload.block.id;
        if (state.blocks != undefined) {
          const blockToRemove = find(state.blocks, (block) => {
            return block.id == blockId;
          });

          let blocks;
          let decrementAmount = 0;

          if (largeBlock(blockToRemove)) {
            const emptyBlockOne = blockToRemove.position + 1;
            const emptyBlockTwo = blockToRemove.position + 2;

            remove(state.blocks, (block) => {
              return (
                block.position == blockToRemove.position ||
                block.position == emptyBlockOne ||
                block.position == emptyBlockTwo
              );
            });

            decrementAmount = 3;
          } else {
            remove(state.blocks, (block) => {
              return block.id == blockToRemove.id;
            });

            decrementAmount = 1;
          }

          blocks = map(state.blocks, (block, index) => {
            if (index >= blockToRemove.position - 1) {
              return {
                ...block,
                position: block.position - decrementAmount,
              };
            } else {
              return block;
            }
          });

          state.blocks = storyBlocks(blocks);
        }
      })
      .addCase(fetchStory.fulfilled, (state, action) => {
        state.blocks = action.payload.story.contents;
        state.storyId = action.payload.story.id;
      });
  },
});

const { actions, reducer } = itemsSlice;

export const selectBlockById = (state, id) =>
  find(state.entities.items.blocks, { id: id });
export const selectBlocks = (state) => state.entities.items.blocks;
export const selectStoryId = (state) => state.entities.items.storyId;

export const {
  blockFakeRemove,
  blockFakeAdd,
  updateStoryBlocks,
  expandBlock,
  compressBlock,
  addTextBlock,
  cancelAddTextBlock,
  updateBlockData,
} = actions;
export default reducer;
