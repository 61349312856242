import React, { Component } from "react";
import RecordThumbnail from "src/components/RecordCard/RecordThumbnail";
import EditDnzMetaForm from "./EditDnzMetaForm";
import Parameterize from "parameterize";
import unescapeXMLCharacters from "src/utils/unescapeXMLCharacters";
import inBrowser from "src/utils/inBrowser";
import { last, head, truncate, keys } from "lodash";
import classNames from "classnames";
import Modal from "src/components/Modal";
import { blockRemove } from "src/features/ItemsSlice";

const UNAVAILABLE_STORY_CONTENT = {
  deleted: {
    heading: "Item no longer available",
    tooltip: "The content that this item relates to has been removed.",
  },
  suppressed: {
    heading: "Item not currently available",
    tooltip: "The content that this item relates to is not currently available",
  },
};

class StackBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteMode: false,
      deleting: false,
      storyId: null,
    };

    this.setDelete.bind(this);
  }

  componentDidMount() {
    if (inBrowser()) {
      const storyId = last(window.location.href.split("/"));
      this.setState({ storyId: storyId });
    }
  }

  setDelete() {
    this.setState({ deleteMode: !this.state.deleteMode });
  }

  async removeBlock(id) {
    this.setState({ deleting: true });
    await this.props.dispatch(
      blockRemove({ storyId: this.state.storyId, blockId: id })
    );
    this.setState({ deleting: false });
    this.setDelete();
  }

  render() {
    const {
      content,
      meta,
      Overlay,
      id,
      storyId,
      user,
      updateBlock,
      activeRecordItem,
      doneEditing,
      isOver,
      dispatch,
    } = this.props;

    const category = meta.category ? [meta.category] : content.category;
    const escapedTitle = unescapeXMLCharacters(content.title);
    const truncatedTitle = truncate(escapedTitle, { length: 150 });

    const titleTag = (
      <h2
        className="story-blocks__block-container__details__custom-title"
        title={meta.title}
      >
        {truncate(meta.title, { length: 250 })}
      </h2>
    );

    const caption = (
      <p className="story-blocks__block-container__details__caption">
        {meta.caption}
      </p>
    );

    const editForm = (
      <EditDnzMetaForm
        updateBlock={updateBlock}
        meta={meta}
        title={escapedTitle}
        user={user}
        doneEditing={doneEditing}
        id={id}
        storyId={storyId}
        dispatch={dispatch}
        imageUrl={content.imageUrl}
      />
    );

    const removeItem = (
      <Modal
        isOpen={this.state.deleteMode}
        onRequestClose={() => (!this.state.deleting ? this.setDelete() : null)}
        className="share-modal generic-modal"
        title="Remove item from story"
      >
        <div className="generic-modal__content">
          <p>
            Are you sure you want to remove <strong>'{escapedTitle}'</strong>{" "}
            from this story?
          </p>
          <p>You cannot undo this action.</p>
        </div>
        <hr />
        <div className="generic-modal__actions">
          <button
            className="button clear"
            aria-label="Cancel"
            onClick={() => this.setDelete()}
            disabled={this.state.deleting}
          >
            Cancel
          </button>
          <button
            className="button"
            aria-label="Remove item"
            onClick={() => this.removeBlock(id)}
            disabled={this.state.deleting}
          >
            <i
              className={`fa fa-circle-o-notch fa-spin start ${
                this.state.deleting ? "show" : "hide"
              }`}
            ></i>
            Remove
          </button>
        </div>
      </Modal>
    );

    const isSmallBlock = meta.alignMode === 0;

    let recordLink = { title: escapedTitle };
    if (!keys(UNAVAILABLE_STORY_CONTENT).includes(content.status)) {
      const parameters = truncate(Parameterize(escapedTitle), {
        length: 75,
        omission: "",
      });
      recordLink = {
        ...recordLink,
        href: `/records/${content.id}/${parameters}?from-story=${this.state.storyId}`,
      };
    }

    let source = head(content.contentPartner);
    if (content.displayCollection == "User Uploaded Content") {
      source = `Uploaded by ${head(content.contentPartner)} user ${head(
        content.contributingPartner
      )}`;
    }

    const equalizerClasses = classNames({
      "story-blocks__block-container__details__equalizer--grey":
        meta.title || meta.caption,
      "story-blocks__block-container__details__equalizer": true,
    });

    return (
      <div
        className={`story-blocks__block-container grid-x story-blocks__block-container--${
          content.status
        } ${isOver ? "story-blocks__block-container--drop-target" : ""} ${
          isSmallBlock ? "story-blocks__block-container--small" : ""
        }`}
      >
        <div
          className={`medium-${
            isSmallBlock ? "12" : "6"
          } cell story-blocks__block-container__thumbnail`}
        >
          <div className="absolute-container absolute-container--right">
            {keys(UNAVAILABLE_STORY_CONTENT).includes(content.status) && (
              <div
                className={`story-blocks__block-container__thumbnail__image-container__${content.status}`}
              >
                {UNAVAILABLE_STORY_CONTENT[content.status].heading} &nbsp;
                <span
                  aria-haspopup="true"
                  data-tooltip
                  className="has-tip bottom"
                  title={UNAVAILABLE_STORY_CONTENT[content.status].tooltip}
                >
                  <i className="fa fa-exclamation-triangle" />
                </span>
              </div>
            )}
            <Overlay
              blockId={id}
              type={"embed"}
              title={escapedTitle}
              {...this.props}
              setDelete={this.setDelete.bind(this)}
            />
          </div>
          <a {...recordLink}>
            <RecordThumbnail {...content} category={category} link={true} />
          </a>
        </div>
        <div
          className={`medium-${
            isSmallBlock ? "12" : "6"
          } cell story-blocks__block-container__details`}
        >
          <div className={equalizerClasses} data-equalizer-watch>
            {meta.title && titleTag}
            {meta.caption && caption}
          </div>

          <a {...recordLink}>
            {meta.title ? truncatedTitle : <h2>{truncatedTitle}</h2>}
          </a>

          <p className="story-blocks__block-container__details__source">
            {source}
          </p>
        </div>
        {id === activeRecordItem && editForm}
        {this.state.deleteMode && removeItem}
      </div>
    );
  }
}

export default StackBlock;
