import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { request } from "src/utils/request";
import { snakeToCamelCase } from "src/utils/keyConverters";
import { filter, omit, remove } from "lodash";
import { blockRemove, fetchStory } from "./ItemsSlice";

export const createStory = createAsyncThunk(
  "stories/createStoryStatus",
  async (params) => {
    const { name } = params;
    const url = "/api/stories.json";

    const response = await request({
      url,
      body: { name: name },
      options: { method: "POST" },
    }).then((req) => req.json());

    return response;
  }
);

export const updateStory = createAsyncThunk(
  "stories/updateStoryStatus",
  async ({ storyId, payload }) => {
    const response = await request({
      url: `/api/stories/${storyId}.json`,
      body: payload,
      options: { method: "PATCH" },
    }).then((req) => req.json());

    return response;
  }
);

export const removeItem = createAsyncThunk(
  "stories/removeItemStatus",
  async (payload) => {
    const { blockId, storyId } = payload;

    if (typeof blockId !== "undefined") {
      await request({
        url: `/api/stories/${storyId}/items/${blockId}`,
        body: {},
        options: { method: "DELETE" },
      }).then((req) => req.json());

      return { blockId: blockId, storyId: storyId };
    } else {
      return payload;
    }
  }
);

const storiesAdapter = createEntityAdapter();

const storiesSlice = createSlice({
  name: "stories",
  initialState: {},
  reducers: {
    addItemToStory(state, action) {
      let { storyId, storyItem } = action.payload;
      storyItem = snakeToCamelCase(storyItem);
      let story = state.entities[storyId];

      story.recordIds.push({
        recordId: storyItem.recordId,
        storyItemId: storyItem.id,
      });

      const itemCount = filter(story.recordIds, function (record) {
        return record.recordId != null;
      });

      story.numberOfItems = itemCount.length;
      state.entities[storyId] = story;
    },
    addStory(state, action) {
      storiesAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateStory.fulfilled, (state, action) => {
        const story = omit(action.payload, "contents");
        storiesAdapter.setOne(state, story);
      })
      .addCase(removeItem.fulfilled, (state, action) => {
        const { blockId, storyId } = action.payload;
        let story = state.entities[storyId];

        remove(story.recordIds, function (record) {
          return record.storyItemId == blockId;
        });
        story.numberOfItems = story.recordIds.length;
        state.entities[storyId] = story;
      })
      .addCase(blockRemove.fulfilled, (state, action) => {
        const storyId = action.payload.storyId;
        state.entities[storyId].numberOfItems -= 1;
      })
      .addCase(fetchStory.fulfilled, (state, action) => {
        const story = omit(action.payload.story, "contents");
        storiesAdapter.setOne(state, story);
      });
  },
});

export const {
  selectById: selectStoryById,
  selectIds: selectStoryIds,
  selectEntities: selectStoryEntities,
  selectAll: selectAllStories,
  selectTotal: selectTotalStories,
} = storiesAdapter.getSelectors((state) => state.entities.stories);

const { actions, reducer } = storiesSlice;
export const { addItemToStory, addStory } = actions;

export default reducer;
