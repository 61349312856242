import React, { useState, useEffect } from "react";

const BlockWrapper = ({ BlockComponent, dragRef, actionProps, blockProps }) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    if (blockProps.monitor.isDragging()) {
      const interval = setInterval(() => {
        window.scrollBy(0, scrollY);
      }, 100);

      return () => clearInterval(interval);
    }
  }, [scrollY, blockProps.monitor]);

  const updateScrolling = () => {
    const { y } = blockProps.monitor.getClientOffset();
    const height = window.innerHeight;
    const buffer = height * 0.3;
    const maxScroll = 60;

    if (y <= buffer) {
      const strength = ((buffer - y) * maxScroll) / buffer;
      setScrollY(-strength); // scroll up
    } else if (y >= height - buffer) {
      const strength = (Math.abs(height - buffer - y) * maxScroll) / buffer;
      setScrollY(+strength); // scroll down
    } else {
      setScrollY(0);
    }
  };

  return (
    <div
      ref={dragRef}
      className="block-wrapper"
      onDrag={updateScrolling}
      onDragEnd={() => setScrollY(0)}
    >
      <div {...actionProps}>
        <BlockComponent {...blockProps} />
      </div>
    </div>
  );
};

export default BlockWrapper;
