import React from "react";
import { StoryHeader } from "../storyheader";
import BaseLayout from "./Base";
import { includes } from "lodash";

const ShowStoryLayout = ({ children, metadata, ...otherProps }) => {
  const header = <StoryHeader {...metadata} />;
  const { user } = otherProps;
  const searchParam = new URLSearchParams({ search: metadata.id });

  return (
    <BaseLayout Header={header} id={metadata.id}>
      {includes(user.roles, "admin") && (
        <div className="story-header story-header--moderation">
          <div className="story-header__content text-center">
            This story is {metadata.privacy}
            <br />
            <a href={`/admin/stories?${searchParam}`}>Moderate</a>
          </div>
        </div>
      )}
      {React.cloneElement(children, otherProps)}
    </BaseLayout>
  );
};

export default ShowStoryLayout;
