require("isomorphic-fetch");
require("es6-promise/auto");

import React from "react";
import { Provider } from "react-redux";
import "react-dates/initialize";
import Main from "src/components/Dashboard/Main";
import ErrorBoundary from "src/components/ErrorBoundary";
import configureAppStore from "src/store";
import { dashboardReducer } from "src/reducers";

const DashboardApp = (props) => (
  <ErrorBoundary>
    <Provider store={configureAppStore(dashboardReducer, props)}>
      <Main />
    </Provider>
  </ErrorBoundary>
);

export default DashboardApp;
