import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import {
  UilEllipsisH,
  UilShrink,
  UilArrowsHAlt,
  UilEditAlt,
  UilFileEditAlt,
  UilTrashAlt,
} from "@iconscout/react-unicons";
import {
  expandBlock,
  compressBlock,
  blockMetadataUpdate,
} from "src/features/ItemsSlice";
import { editCaptionAndNotes } from "src/features/EditStorySlice";
import { fetchUserImageRecord } from "src/features/RecordsSlice";
import {
  selectDropdown,
  openDropdown,
  closeDropdown,
} from "src/features/DropdownSlice";

import {
  setEditingUserImage,
  openEditUserImageModal,
} from "src/features/EditUserImageSlice";
import { featureIsEnabled } from "src/features/PlugFeaturesSlice";

const Overlay = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { dropdownID } = useSelector(selectDropdown);
  const imageUploadEnabled = useSelector((state) =>
    featureIsEnabled(state, "user-image-upload")
  );

  const {
    activeRecordItem,
    type,
    blockId,
    setDelete,
    meta,
    belongsToUser,
    imageId,
    content,
  } = props;

  useEffect(() => {
    const handleClickOffDropdown = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (blockId == dropdownID) {
          dispatch(closeDropdown());
        }
      }
    };

    document.addEventListener("mousedown", handleClickOffDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOffDropdown);
    };
  }, [dropdownID, dispatch, blockId]);

  if (!props.user.loggedIn || !props.modifiable) {
    return null;
  }

  const alignment = meta.alignMode;

  const handleEditImageMetadataClick = () => {
    props.dispatch(fetchUserImageRecord(imageId));
    props.dispatch(setEditingUserImage(content.id));
    props.dispatch(openEditUserImageModal());
  };

  const handleEditCaptionAndNotes = () => {
    props.dispatch(closeDropdown());
    props.dispatch(editCaptionAndNotes(blockId));
  };

  const fadeOut = (id, cb) => {
    const elem = $(`#story-block-${id}`);
    if (process.env.NODE_ENV != "test") {
      Foundation.Motion.animateOut(elem, "fade-out", cb);
    }
  };

  const fadeIn = (id, cb) => {
    const elem = $(`#story-block-${id}`);
    if (process.env.NODE_ENV != "test") {
      Foundation.Motion.animateIn(elem, "fade-in", cb);
    }
  };

  const expandCb = async () => {
    props.dispatch(expandBlock(blockId));
    props.dispatch(
      blockMetadataUpdate({
        storyId: props.storyId,
        fieldName: "alignMode",
        value: 1,
        blockId: blockId,
      })
    );

    props.dispatch(closeDropdown());
    fadeIn(blockId, () => {});
  };

  const compressCb = () => {
    props.dispatch(compressBlock(blockId));
    props.dispatch(
      blockMetadataUpdate({
        storyId: props.storyId,
        fieldName: "alignMode",
        value: 0,
        blockId: blockId,
      })
    );
    props.dispatch(closeDropdown());

    fadeIn(blockId, () => {});
  };

  let badge;
  if (belongsToUser) {
    badge = (
      <div className="top-triangle-container__label">
        <span className="top-triangle-container__label__content">
          YOUR UPLOAD
        </span>
      </div>
    );
  }

  let alignmentElem = (
    <button
      className="hide-for-small-only"
      onClick={() => {
        fadeOut(blockId, expandCb);
      }}
    >
      <UilArrowsHAlt /> Make item full width
    </button>
  );
  if (alignment !== 0) {
    alignmentElem = (
      <button
        className="hide-for-small-only"
        onClick={() => {
          fadeOut(blockId, compressCb);
        }}
      >
        <UilShrink /> Make item 1/3 width
      </button>
    );
  }

  const hideButtonClass = activeRecordItem == blockId ? " hide" : "";

  const dropdownClasses = classNames({
    "dropdown-pane": true,
    "dropdown-pane--visible-large": dropdownID == blockId && alignment == 1,
    "dropdown-pane--visible-small": dropdownID == blockId && alignment == 0,
  });

  const handleDropdownClick = () => {
    if (dropdownID == blockId) {
      dispatch(closeDropdown());
    } else {
      dispatch(openDropdown(blockId));
    }
  };

  return (
    <div ref={ref} className="story-blocks__overlay">
      {badge}
      {type === "embed" && (
        <React.Fragment>
          <button
            className={`story-blocks__overlay__button${hideButtonClass}`}
            type="button"
            aria-label="Toggle the edit dropdown"
            onClick={handleDropdownClick}
          >
            <UilEllipsisH />
          </button>
          <div className={dropdownClasses} id={`edit-dropdown-${blockId}`}>
            {alignmentElem}

            <hr className="hide-for-small-only" />
            <button onClick={handleEditCaptionAndNotes}>
              <UilEditAlt /> Edit caption and notes
            </button>
            {belongsToUser && imageUploadEnabled && (
              <button onClick={handleEditImageMetadataClick}>
                <UilFileEditAlt /> Edit image metadata
              </button>
            )}
            <hr />
            <button
              onClick={() => {
                setDelete();
              }}
            >
              <UilTrashAlt /> Remove item from story
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Overlay;
