import Modal from "src/components/Modal";
import React from "react";
import { useSelector } from "react-redux";
import { selectEditStory } from "src/features/EditStorySlice";

const StoryError = () => {
  const storyError = useSelector((state) => selectEditStory(state).storyError);

  return (
    <div className="story-error">
      <Modal
        isOpen={storyError}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        shouldHaveCloseButton={false}
        className="generic-modal"
        title="This story is out of sync"
      >
        <div className="generic-modal__content">
          <p>
            It looks like this story has been edited in another window. Please
            reload the page to update the story. Your most recent edit may be
            lost.
          </p>
        </div>
        <hr />
        <div className="generic-modal__actions">
          <button
            className="button"
            onClick={() => window.location.reload(false)}
          >
            Reload Page
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default StoryError;
