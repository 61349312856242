import React from "react";
import { useSelector } from "react-redux";
import Masonry from "react-masonry-component";
import BaseLayout from "../../layouts/Base";
import withMyStories from "src/components/RecordCard/hoc/withMyStories";
import RecordCard from "src/components/RecordCard/RecordCard";
import { map } from "lodash";
import { selectAllStories } from "src/features/StoriesSlice";

const WithMyStories = withMyStories(RecordCard);

const MyStories = () => {
  const stories = useSelector(selectAllStories);
  const header = <h1 className="header">My Stories</h1>;

  return (
    <BaseLayout Sidebar={null} Header={header}>
      <div className="grid-container">
        <Masonry
          className="masonry-card-layout grid-x grid-padding-x small-up-1 medium-up-2 large-up-4"
          options={{ gutter: 0 }}
        >
          {map(stories, (s) => {
            return (
              <div className="cell masonry-card-layout__item" key={s.id}>
                <WithMyStories {...s} />
              </div>
            );
          })}
        </Masonry>
      </div>
    </BaseLayout>
  );
};

export default MyStories;
