import { bindAll } from "lodash";
import React, { Component } from "react";
import classNames from "classnames";
import InputCounter from "src/utils/inputCounter";

class ImageDescriberForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title || "",
      description: props.description || "",
      subject: props.subject || "",
      displayDate: props.displayDate || "",
      creator: props.creator || "",
      errors: {
        title: [],
        description: [],
        subject: [],
        displayDate: [],
        creator: [],
      },
    };

    bindAll(
      this,
      "handleSubmit",
      "handleTitleChange",
      "handleDescriptionChange",
      "handleSubjectChange",
      "handleDisplayDateChange",
      "handleCreatorChange"
    );
  }

  componentDidMount() {
    InputCounter.init();
    $("input#displayDate").datepicker({
      dateFormat: "dd/mm/yy",
      onSelect: this.handleDisplayDateChange,
    });
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }

  handleDisplayDateChange(value) {
    this.setState({ displayDate: value });
  }

  handleCreatorChange(event) {
    this.setState({ creator: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const errors = { title: [], description: [] };
    if (this.state.title === "") {
      errors.title.push("Please fill in this field");
    }
    if (this.state.description === "") {
      errors.description.push("Please fill in this field");
    }
    if (errors.title.length === 0 && errors.description.length === 0) {
      this.props.onValidDescriptionSubmit(this.state);
    } else {
      this.setState({ errors: errors });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="describe">
        <div className="grid-x grid-margin-x generic-modal__content">
          <div className="cell medium-6 image-being-uploaded-box">
            <img src={this.props.image} className="image-being-uploaded" />
          </div>
          <div className="cell medium-6">
            <div className="grid-x align-right">
              {this.state.errors.length > 0 && (
                <div className="cell">
                  <ul>
                    {this.state.errors.map((error, i) => (
                      <li key={i}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}

              <div
                className={classNames("cell", {
                  field_with_errors: this.state.errors.title.length,
                })}
              >
                <div className="grid-x">
                  <div className="cell auto"></div>
                  <span className="required cell shrink">required fields</span>
                </div>
                <div className="grid-x">
                  <label htmlFor="title" className="required cell auto">
                    Title <span className="input-counter">120</span>
                  </label>
                </div>
                <input
                  required
                  id="title"
                  name="title"
                  type="text"
                  value={this.state.title}
                  autoFocus
                  onChange={this.handleTitleChange}
                  maxLength="120"
                />
                {this.state.errors.title.length
                  ? this.state.errors.title.map((error, i) => (
                      <p key={i}>{error}</p>
                    ))
                  : null}
              </div>

              <div
                className={`cell${
                  this.state.errors.description.length
                    ? " field_with_errors"
                    : ""
                }`}
              >
                <label htmlFor="description" className="required">
                  Description <span className="input-counter">400</span>
                </label>
                <textarea
                  required
                  rows="5"
                  id="description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleDescriptionChange}
                  maxLength="400"
                />
                {this.state.errors.description.length
                  ? this.state.errors.description.map((error, i) => (
                      <p key={i}>{error}</p>
                    ))
                  : null}
              </div>

              <div
                className={`cell${
                  this.state.errors.subject.length ? " field_with_errors" : ""
                }`}
              >
                <label htmlFor="subject">
                  <div>
                    Related subjects <span className="input-counter">120</span>
                  </div>
                  <i className="font-normal">
                    Please separate these with commas.
                  </i>
                </label>
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  value={this.state.subject}
                  onChange={this.handleSubjectChange}
                  maxLength="120"
                />
                {this.state.errors.subject.length
                  ? this.state.errors.subject.map((error, i) => (
                      <p key={i}>{error}</p>
                    ))
                  : null}
              </div>

              <div
                className={`cell${
                  this.state.errors.creator.length ? " field_with_errors" : ""
                }`}
              >
                <label htmlFor="creator">
                  Creator <span className="input-counter">120</span>
                </label>
                <input
                  id="creator"
                  name="creator"
                  type="text"
                  defaultValue={this.state.creator}
                  onChange={this.handleCreatorChange}
                  maxLength="120"
                />
                {this.state.errors.creator.length
                  ? this.state.errors.creator.map((error, i) => (
                      <p key={i}>{error}</p>
                    ))
                  : null}
              </div>

              <div
                className={`cell${
                  this.state.errors.displayDate.length
                    ? " field_with_errors"
                    : ""
                }`}
              >
                <label htmlFor="displayDate">Date created</label>
                <input
                  id="displayDate"
                  name="displayDate"
                  type="text"
                  onChange={(e) => this.handleDisplayDateChange(e.target.value) }
                  defaultValue={this.state.displayDate}
                />
                {this.state.errors.displayDate.length
                  ? this.state.errors.displayDate.map((error, i) => (
                      <p key={i}>{error}</p>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="generic-modal__actions">
          <button
            className="button clear"
            onClick={this.props.onBack}
            type="button"
          >
            {this.props.backLabel}
          </button>
          <button className="button" type="submit">
            {this.props.isSubmitting && (
              <i className="fa fa-circle-o-notch fa-spin start"></i>
            )}
            {this.props.submitLabel}
          </button>
        </div>
      </form>
    );
  }
}

export default ImageDescriberForm;
