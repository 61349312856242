import React, { Component } from "react";
import { bindAll, trim } from "lodash";

class CreateStoryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      valid: true,
    };

    bindAll(this, "onChange", "handleSubmit");
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { value } = this.state;
    const { storyCreateCb } = this.props;

    if (trim(value)) {
      this.setState({ valid: true });
      storyCreateCb(value);
    } else {
      this.setState({ valid: false });
    }
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="generic-modal__content">
            <p>
              Stories are groups of items you create and enhance.
              <br />
              <a href="/help">Learn more</a>
            </p>

            <label htmlFor="title" className="required">
              Title
            </label>
            <input
              id="title"
              type="text"
              placeholder="Enter a title for your story"
              value={this.state.value}
              onChange={this.onChange}
              autoFocus
              required
            />
            {!this.state.valid && <p>The story title is required.</p>}
          </div>
          <hr />
          <div className="generic-modal__actions">
            <button
              className="button clear"
              onClick={this.props.toggleOpenCb}
              aria-label="Close popup"
              type="button"
              title="Cancel and close popup"
            >
              Cancel
            </button>
            <button
              className="button"
              type="submit"
              aria-label="Create a story and add this item to your new story"
              title="Create story"
              disabled={!trim(this.state.value) || this.props.loading}
            >
              {this.props.loading && (
                <i className="fa fa-circle-o-notch fa-spin start"></i>
              )}
              Create story
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default CreateStoryForm;
