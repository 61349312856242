import React from "react";
import { Link } from "react-router-dom";

const RecordLink = ({ isExternalLink, link, children, ...props }) => {
  if (isExternalLink) {
    return (
      <a {...props} href={link}>
        {children}
      </a>
    );
  } else {
    return (
      <Link {...props} to={link}>
        {children}
      </Link>
    );
  }
};

export default RecordLink;
