import dnzNil from "./dnzNil";
import moment from "moment";

export default function formatDate(
  dateStr,
  expectedFormat = null,
  formatTo = null,
  fallbackStr = ""
) {
  if (dnzNil(dateStr)) return fallbackStr;

  formatTo = formatTo || "DD MMMM YYYY";
  const momentDate =
    expectedFormat === null
      ? moment(dateStr)
      : moment(dateStr, expectedFormat, true);

  return momentDate.isValid() ? momentDate.format(formatTo) : dateStr;
}
