import React from "react";
import RichText from "./RichText";
import TipTap from './TipTap';

const modeMappings = {
  editing: TipTap,
  stack: RichText,
};

const RichTextBlock = ({ mode, ...otherProps }) => {
  const Component = modeMappings[mode];

  return <Component {...otherProps} />;
};

export default RichTextBlock;
