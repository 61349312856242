import Modal from "src/components/Modal";
import React, { Component } from "react";
import { bindAll } from "lodash";

class CopyrightLicence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copyrightModal: false,
    };

    bindAll(this, "closeModal", "openModal");
  }

  closeModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  openModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  render() {
    return (
      <div className="story-header-edit__help-share__license">
        <button
          className="button white hollow"
          id="copyright-licence"
          onClick={() => this.openModal("copyrightModal")}
        >
          <span>Check copyright of this story</span>
        </button>

        <Modal
          isOpen={this.state.copyrightModal}
          onRequestClose={() => this.closeModal("copyrightModal")}
          className="generic-modal modal"
          title="What is the copyright status of this story?"
        >
          <p className="generic-modal__content">
            This story may include original text by the identified creator
            and/or copies of text by other people. It is the creator’s
            responsibility to ensure that they are licensed to include any text
            by other people or institutions and that they comply with the
            licence of that text. This may include attributing the original
            source and citing the terms on which that text is licensed. If you
            think any text has been included in a way that contravenes any
            licence conditions or copyright,{" "}
            <a href="mailto:info@digitalnz.org?subject=Stories copyright enquiry">
              please let us know
            </a>
            . To the extent the creator owns copyright in this story, it is
            licensed under a{" "}
            <a href="https://creativecommons.org/licenses/by/4.0/">
              Creative Commons Attribution 4.0 License
            </a>
          </p>
          <hr />
          <div className="generic-modal__actions">
            <button
              role="button"
              type="button"
              className="button"
              onClick={() => this.closeModal("copyrightModal")}
            >
              Got it
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CopyrightLicence;
