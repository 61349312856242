import React from "react";
import { useDispatch } from "react-redux";
import { toggleFacet } from "src/features/SearchSlice";

const IsolatedFilter = ({ filter, performSearch }) => {
  const dispatch = useDispatch();

  const removeFilter = async (filter) => {
    await dispatch(toggleFacet(filter));
    performSearch();
  };

  function displayTitleFor(filter) {
    return filter.replace("_", "-");
  }

  return (
    <button
      className="search-panel__header__item search-panel__header__item--with-filters"
      onClick={() => removeFilter(filter)}
    >
      {displayTitleFor(filter.type || filter.facet)}:{" "}
      {filter.customLabel || filter.value}
      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
  );
};

export default IsolatedFilter;
