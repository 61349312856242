import React, { Component } from "react";
import Story from "./Story";
import Modal from "src/components/Modal";
import inBrowser from "src/utils/inBrowser";
import { bindAll } from "lodash";

import { updateStory } from "src/features/StoriesSlice";

class StoryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacyModal: false,
      privateModal: false,
      publicModal: false,
    };

    bindAll(this, "makeStoryPrivate", "openModal", "closeModal");
  }

  componentDidMount() {
    if (inBrowser() && process.env.NODE_ENV != "test") {
      window.scrollTo(0, 0);
      $(document).foundation();
    }
  }

  openModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  closeModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  makeStoryPrivate() {
    this.props.dispatch(
      updateStory({ storyId: this.props.id, payload: { privacy: "private" } })
    );
    this.closeModal("privacyModal");
    this.openModal("privateModal");
  }

  keepStoryPublic() {
    this.closeModal("privacyModal");
    this.openModal("publicModal");
  }

  render() {
    const { privacyModal, privateModal, publicModal } = this.state;

    return (
      <>
        <Story
          storyId={this.props.id}
          editable={this.props.editable}
          openPrivacyModal={() => this.openModal("privacyModal")}
        />

        <Modal
          isOpen={privacyModal}
          onRequestClose={() => this.closeModal("privacyModal")}
          className="generic-modal"
          title="Make this story private while you are editing?"
        >
          <div className="generic-modal__content">
            <p>
              Changes to your story may be viewed by anyone. Do you want us to
              make your story private while you're writing?
            </p>

            <p>
              By saving a story on DigitalNZ, you agree to our{" "}
              <a
                href="/about/terms-of-use#contributedcontent"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>{" "}
              and release your story under a{" "}
              <a
                href="https://creativecommons.org/licenses/by/4.0/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Creative Commons CC-BY license.
              </a>
            </p>
          </div>
          <hr />
          <div className="generic-modal__actions">
            <button
              className="button"
              aria-label="Close modal"
              onClick={() => this.makeStoryPrivate()}
              id="delete-story-privacy-modal"
            >
              Yes, make my story private
            </button>
            <button
              className="button"
              aria-label="Close modal"
              id="dont-delete-story-privacy-modal"
              onClick={() => this.keepStoryPublic()}
            >
              No, Keep it public
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={privateModal}
          onRequestClose={() => this.closeModal("privateModal")}
          className="generic-modal"
          title="Story set to private"
        >
          <div className="generic-modal__content">
            <p>
              When you're ready, you can publish your story by changing story
              privacy settings at the top of this page.
            </p>
          </div>
          <hr />
          <div className="generic-modal__actions">
            <button
              className="button"
              onClick={() => this.closeModal("privateModal")}
              aria-label="Close modal"
            >
              OK, got it
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={publicModal}
          onRequestClose={() => this.closeModal("publicModal")}
          className="generic-modal"
          title="Story is public"
        >
          <div className="generic-modal__content">
            <p>
              Any changes you make will be immediately visible to anyone reading
              this story. You can make your story private by changing story
              privacy settings at the top of this page.
            </p>
          </div>
          <hr />
          <div className="generic-modal__actions">
            <button
              className="button"
              onClick={() => this.closeModal("publicModal")}
              aria-label="Close modal"
            >
              OK, got it
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default StoryContainer;
