import React from "react";
import MediaInRecordPage from "src/components/MediaInRecordPage";

import CreativeCommonsImage from "images/creative-commons.png";
import MoreInformation from "./MoreInformation";

const CreativeCommonsRights = (props) => {
  const { licenseType, licenseTitle, licenseVersion, licenseLocale } = props;

  const licenseTypeText = `(${licenseType})`;
  const licenseText = [
    licenseTitle,
    licenseTypeText,
    licenseVersion,
    licenseLocale,
    "license.",
  ].join(" ");

  return (
    <>
      <MediaInRecordPage
        img_src={CreativeCommonsImage}
        img_alt="Creative Commons"
        title="Creative Commons"
      >
        <p>
          This item appears to be licensed under a Creative Commons{" "}
          {licenseText}
        </p>
      </MediaInRecordPage>

      <MoreInformation {...props} />
    </>
  );
};

export default CreativeCommonsRights;
