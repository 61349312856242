import { createSlice } from "@reduxjs/toolkit";
import { submitUserImageEdit, fetchUserImageRecord } from "./RecordsSlice";

const EditUserImageSlice = createSlice({
  name: "editUserImage",
  // These values are overritten by the redux presenter
  initialState: {
    isFetchingUserImageRecord: false,
    submitUserImageEditOpened: false,
    userImageBeingEdited: null,
    isSubmitting: false,
  },
  reducers: {
    setEditingUserImage(state, action) {
      state.userImageBeingEdited = action.payload;
    },
    closeEditUserImageModal(state) {
      state.editUserImageModalOpened = false;
    },
    openEditUserImageModal(state) {
      state.editUserImageModalOpened = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserImageRecord.fulfilled, (state, _action) => {
        state.isFetchingUserImageRecord = false;
      })
      .addCase(fetchUserImageRecord.pending, (state, _action) => {
        state.isFetchingUserImageRecord = true;
      })
      .addCase(fetchUserImageRecord.rejected, (state, _action) => {
        state.userImageError = true;
        state.isSubmitting = false;
        state.isFetchingUserImageRecord = false;
      })
      .addCase(submitUserImageEdit.fulfilled, (state, _action) => {
        state.isSubmitting = false;
        state.editUserImageModalOpened = false;
      })
      .addCase(submitUserImageEdit.pending, (state, _action) => {
        state.isSubmitting = true;
      })
      .addCase(submitUserImageEdit.rejected, (state, _action) => {
        state.userImageError = true;
        state.isSubmitting = false;
      });
  },
});

export const selectEditUserImage = (state) => state.ui.editUserImage;

const { actions, reducer } = EditUserImageSlice;
export const {
  setEditingUserImage,
  closeEditUserImageModal,
  openEditUserImageModal,
} = actions;

export default reducer;
