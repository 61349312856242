import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSearch, updateSearchQuery } from "src/features/SearchSlice";
import { featureIsEnabled } from "src/features/PlugFeaturesSlice";

const SearchBox = ({ switchSearchMode, handleSubmit }) => {
  const dispatch = useDispatch();
  const { query } = useSelector(selectSearch);
  const advancedSearchEnabled = useSelector((state) =>
    featureIsEnabled(state, "advanced-search")
  );

  const handleChange = (event) => {
    event.preventDefault();
    dispatch(updateSearchQuery(event.target.value));
  };

  return (
    <div className="search-box grid-container padding-horizontal-1">
      <form onSubmit={handleSubmit} className="grid-x">
        <input
          type="search"
          className="cell auto search-box__input"
          id="search-input"
          placeholder="Search..."
          value={query}
          onChange={handleChange}
        />
        <button
          type="submit"
          value="Search"
          id="search-button"
          className="cell shrink search-box__button button large"
        >
          <span className="hide-for-small-only">Search</span>
          <i
            aria-hidden="true"
            className="fa fa-2x fa-search show-for-small-only"
          ></i>
        </button>
      </form>
      {advancedSearchEnabled && (
        <div className="grid-x">
          <a
            href="#"
            className="margin-bottom-1"
            role="button"
            onClick={switchSearchMode}
          >
            Advanced search{" "}
            <i aria-hidden="true" className="fa fa-arrow-down"></i>
          </a>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
