import React from "react";
import classNames from "classnames";

const SearchRow = ({
  index,
  deletable,
  handleChange,
  handleDeleteRow,
  fields,
}) => {
  return (
    <div className="grid-x align-middle">
      <div className="cell auto">
        <div className="grid-x align-middle search-row padding-1">
          <div className="cell shrink">
            <select
              id="include"
              className="search-row__field"
              value={fields.include}
              onChange={(e) =>
                handleChange(index, {
                  ...fields,
                  include: e.target.value === "true" ? true : false,
                })
              }
            >
              <option value="true">Include</option>
              <option value="false">Exclude</option>
            </select>
          </div>
          <div className="cell shrink">
            <select
              id="exactMatch"
              className="search-row__field"
              value={fields.exactMatch}
              onChange={(e) =>
                handleChange(index, {
                  ...fields,
                  exactMatch: e.target.value === "true" ? true : false,
                })
              }
            >
              <option value="true">Exactly the phrase</option>
              <option value="false">Roughly the phrase</option>
            </select>
          </div>
          <div className="cell auto">
            <input
              className="search-row__field"
              type="text"
              value={fields.terms}
              onChange={(e) =>
                handleChange(index, {
                  ...fields,
                  terms: e.target.value,
                })
              }
            />
          </div>
          <div className="cell shrink">in</div>
          <div className="cell shrink">
            <select
              id="field"
              className="search-row__field"
              value={fields.field}
              onChange={(e) =>
                handleChange(index, {
                  ...fields,
                  field: e.target.value,
                })
              }
            >
              <option value="">Any field</option>
              {/* <option value="category">Category</option> */}
              {/* <option value="century">Century</option> */}
              <option value="primary_collection_text">Collection</option>
              {/* <option value="content_partner">Content partner</option> */}
              {/* <option value="copyright">Copyright</option> */}
              <option value="creator_text">Creator</option>
              {/* <option value="date">Date</option> */}
              {/* <option value="dc_identifier">DC identifier</option> */}
              {/* <option value="dc_type">DC type</option> */}
              {/* <option value="decade">Decade</option> */}
              <option value="description_text">Description</option>
              {/* <option value="format">Format</option> */}
              <option value="placename_text">Placename</option>
              {/* <option value="rights">Rights</option> */}
              <option value="subject_text">Subject</option>
              <option value="title_text">Title</option>
              {/* <option value="usage">Usage</option> */}
              {/* <option value="year">Year</option> */}
            </select>
          </div>
        </div>
      </div>
      <div
        className={classNames("cell shrink padding-horizontal-1", {
          hide: !deletable,
        })}
      >
        <button
          className="button clear"
          onClick={() => handleDeleteRow(index)}
          type="button"
        >
          <span className="fa fa-trash" aria-label="Delete"></span>
        </button>
      </div>
    </div>
  );
};

export default SearchRow;
