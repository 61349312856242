import { map } from "lodash";
import React from "react";

function isLast(array, index) {
  return index === array.length - 1;
}

function determineSeparator(
  array,
  index,
  separator,
  secondToLastSeparator = null
) {
  if (index === 0) return "";

  if (isLast(array, index) && secondToLastSeparator !== null) {
    return secondToLastSeparator;
  } else {
    return separator;
  }
}

export default function convertArray(
  array,
  separator,
  secondToLastSeparator = null
) {
  return map(array, (component, index) => {
    return (
      <React.Fragment key={index}>
        {determineSeparator(array, index, separator, secondToLastSeparator)}
        {component}
      </React.Fragment>
    );
  });
}
