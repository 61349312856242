import React from "react";

import FacetFilterTab from "./FacetFilterTab";
import FacetFilter from "./FacetFilter";
import IsolatedFilter from "./IsolatedFilter";
import FacetFilterActionBar from "./FacetFilterActionBar";

import { useSelector, useDispatch } from "react-redux";
import {
  clearAllFacets,
  toggleFacetTab,
  toggleFacet,
  selectSearch,
} from "src/features/SearchSlice";
import { chain, keys, map, includes, size } from "lodash";
import Analytics from "src/utils/Analytics";
import MasonryLayoutSwitch from "src/components/MasonryLayoutSwitch";
import DateRangeFilter from "./DateRangeFilter";

const DesktopFilter = (props) => {
  const { performSearch } = props;
  const dispatch = useDispatch();
  const {
    activeFacetTab,
    activeFilters,
    hasDifferentFilters,
    facetFiltersWithCount,
  } = useSelector((state) => ({
    activeFacetTab: selectSearch(state).panel.activeFacetTab,
    activeFilters: selectSearch(state).filters,
    hasDifferentFilters: selectSearch(state).hasDifferentFilters,
    facetFiltersWithCount: selectSearch(state).panel.facets,
  }));

  const facetFilterTabs = {
    content_partner: { order: "asc" },
    primary_collection: { order: "asc" },
    usage: { order: "asc" },
    decade: { order: "desc" },
    date_range: { order: "asc" },
  };

  const alwaysDisplayedFilters = [
    "decade",
    "content_partner",
    "primary_collection",
    "usage",
    "date_range",
  ];

  const getOrderedFacetFiltersByFacetAsPair = (facetName) => {
    return (
      chain(facetFiltersWithCount[facetName])
        // I cannnot convert it back to a hash with `fromPairs()`
        // as it will not retain the order when the key is a number
        // so I'm returning a pair [filterName, filterCount]
        .toPairs()
        .orderBy(0, facetFilterTabs[facetName].order)
        .value()
    );
  };

  const getActiveFiltersForFacet = (facetName) =>
    chain(activeFilters)
      .filter((filter) => filter.facet === facetName)
      .map((filter) => filter.value)
      .value();

  const getNumberOfActiveFacets = chain(activeFilters)
    .map((filter) => filter.facet)
    .uniq()
    .size();

  const clearAllFacetsAndSearch = async () => {
    Analytics.event("click", "SearchFilterSelect", "Clear All Facets");
    await dispatch(clearAllFacets());
    performSearch();
  };

  const closeFacetFiltersPanel = () => {
    dispatch(toggleFacetTab());
    if (hasDifferentFilters) performSearch();
  };

  const handleKeyUp = (e) => {
    if (e.key === "Escape") closeFacetFiltersPanel();
  };

  return (
    <div className="search-panel-app show-for-medium grid-container">
      <div className="search-panel" onKeyUp={handleKeyUp}>
        <div className="search-panel__header" role="menubar">
          <div className="search-panel__filters">
            {chain(facetFiltersWithCount)
              .pick(keys(facetFilterTabs))
              .keys()
              .map((facetName) => (
                <FacetFilterTab
                  key={facetName}
                  isActive={activeFacetTab === facetName}
                  activeFilters={activeFilters}
                  facetName={facetName}
                  hasDifferentFilters={hasDifferentFilters}
                  performSearch={performSearch}
                />
              ))
              .value()}

            {chain(activeFilters)
              .filter(
                (activeFilter) =>
                  !includes(alwaysDisplayedFilters, activeFilter.facet)
              )
              .map((activeFilter) => (
                <IsolatedFilter
                  key={activeFilter.facet}
                  filter={activeFilter}
                  performSearch={performSearch}
                />
              ))
              .value()}

            {getNumberOfActiveFacets > 1 && (
              <button
                className="button clear small"
                onClick={clearAllFacetsAndSearch}
              >
                Clear all
                <i className="fa fa-close end" aria-hidden="true"></i>
              </button>
            )}
          </div>
          <MasonryLayoutSwitch />
        </div>

        {activeFacetTab && (
          <>
            <div
              className="search-panel__overlay"
              onClick={() => closeFacetFiltersPanel()}
            />

            <div className="search-panel__content">
              <FacetFilterActionBar
                hasActiveFilters={
                  size(getActiveFiltersForFacet(activeFacetTab)) > 0
                }
                hasDifferentFilters={hasDifferentFilters}
                facetName={activeFacetTab}
                performSearch={performSearch}
              />

              <div className="search-panel__content__filters">
                <div className="search-panel__content__filters__items grid-container">
                  <div className="grid-x grid-margin-x grid-margin-y">
                    {activeFacetTab == "date_range" && (
                      <DateRangeFilter
                        enterCallback={performSearch}
                        enterCallbackType="function"
                      />
                    )}

                    {activeFacetTab != "date_range" &&
                      map(
                        getOrderedFacetFiltersByFacetAsPair(activeFacetTab),
                        ([facetFilterName, facetFilterCount]) => (
                          <FacetFilter
                            key={facetFilterName}
                            toggleFacetFilter={() =>
                              dispatch(
                                toggleFacet({
                                  facet: activeFacetTab,
                                  value: facetFilterName,
                                })
                              )
                            }
                            isActive={includes(
                              getActiveFiltersForFacet(activeFacetTab),
                              facetFilterName
                            )}
                            facetFilterName={
                              activeFacetTab === "decade"
                                ? `${facetFilterName}s`
                                : facetFilterName
                            }
                            facetFilterCount={facetFilterCount}
                          />
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DesktopFilter;
