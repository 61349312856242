import React from "react";
import map from "lodash/map";

const FacetSelector = ({ action, value, values }) => {
  const options = map(values, (v) => (
    <option key={v} value={v}>
      {v == "all" ? "All collections" : v}
    </option>
  ));

  return (
    <div className="dashboard-select-input medium 12-columns">
      <select onChange={(e) => action(e.target.value)} value={value}>
        {options}
      </select>
    </div>
  );
};

export default FacetSelector;
