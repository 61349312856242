import React, { useState } from 'react';
import { range, chunk, map, first, last } from 'lodash';
import classnames from "classnames";

const YearPicker = React.forwardRef((props, ref) => {

  let { fromYear, toYear, type, callback } = props;
  const pageSize = 18;

  const minYear = 1;
  const maxYear = new Date().getFullYear();

  if(fromYear == undefined || fromYear == '') {
    fromYear = '1801';
  }

  if(toYear == undefined || toYear == '') {
    toYear = maxYear;
  } 

  const years = range(minYear, (maxYear + 1));
  const pages = chunk(years, pageSize);

  const initialPage = () => {
    let selectedYear;

    if(type == 'from') {
      selectedYear = fromYear;
    } 

    if(type == 'to') {
      selectedYear = toYear;
    }

    return (Math.ceil(selectedYear / pageSize));
  }

  const [pageValue, setPageValue] = useState(initialPage())

  const currentPage = () => {
    return pages[pageValue - 1]
  }

  const previousPage = () => {
    if(pageValue != 1) {
      setPageValue(pageValue - 1);
    }
  }

  const nextPage = () => {
    if(pageValue != pages.length) {
      setPageValue(pageValue + 1);
    }
  }

  const activeYear = (year) => {
    if(type == 'from') {
      return year == fromYear;
    }

    if(type == 'to') {
      return year == toYear;
    }
  }

  return(
    <div className='year-picker' ref={ref}>
      <div className='year-picker__header'>
        { pageValue != 1 && <span className="float-left year-picker__control" aria-hidden="true" onClick={ () => previousPage() }></span> }
        { pageValue == 1 && <span className="float-left year-picker__control year-picker__control--spacer" aria-hidden="true"></span> }

        { pageValue != pages.length && <span className="float-right year-picker__control year-picker__control--forward" aria-hidden="true" onClick={ () => nextPage() }></span> }
        { pageValue == pages.length && <span className="float-right year-picker__control year-picker__control--spacer" aria-hidden="true"></span> }

        <h5 className='text-center year-picker__heading'>Year {first(currentPage())} - {last(currentPage())}</h5>
      </div>

      <div className='grid-x text-center margin-top-1'>
        { map(currentPage(), (year) => {
          return(
            <div 
              className={classnames({
                'cell': true,
                'small-2': true,
                'year-picker__year': true,
                'year-picker__year--active': activeYear(year)
              })}
              onClick={ () => callback(type, year)}
            >
              { year }
            </div>
          )
        })}
      </div>
    </div>
  )
});

export default YearPicker;
