import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseOnEscape from "react-close-on-escape";
import { filter, isEmpty, includes } from "lodash";
import sanitizeHtml from "sanitize-html";
import inBrowser from "src/utils/inBrowser";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";

import { selectBlocks } from "src/features/ItemsSlice";
import {
  blockUpdate,
  blockAdd,
  cancelAddTextBlock,
} from "src/features/ItemsSlice";
import DeleteTextModal from "./DeleteTextModal";
import LinkModal from "./LinkModal";

export default (props) => {
  const dispatch = useDispatch();

  const [modalOpened, setModalOpened] = useState(false);
  const [value, setValue] = useState(props.content.value);
  const [disableSave, setDisableSave] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [linkHref, setLinkHref] = useState("");
  const [linkExternal, setLinkExternal] = useState(false);

  function countStoryTextBlocks(state) {
    return filter(selectBlocks(state), (content) => {
      return content.type === "text";
    }).length;
  }

  const storyTextBlockCount = useSelector(countStoryTextBlocks);

  useEffect(() => {
    // This is equivilent to componentDidMount
    if (inBrowser()) {
      window.onbeforeunload = () => {
        finishEditing();
        return confirm("Your changes will be lost");
      };
    }

    setDisableSave(isEmpty(parseTextContent(value)));

    // This is equivilent to componentWillUnmount
    return () => {
      if (inBrowser()) {
        window.onbeforeunload = null;
      }
    }
  }, [value]);

  const editor = useEditor({
    content: props.content.value,
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        autolink: true,
        HTMLAttributes: {
          rel: "",
          target: null,
        },
      }),
      Placeholder.configure({
        placeholder: "type here",
      }),
    ],

    onUpdate({ editor }) {
      const content = editor.getHTML();
      setValue(content);
      setDisableSave(isEmpty(parseTextContent(content)));
    },
  });

  const parseTextContent = (content) => {
    // Removing empty tags
    return sanitizeHtml(content, {
      exclusiveFilter: function (frame) {
        return !frame.text.trim();
      },
      allowedTags: false,
    }).trim();
  };

  const submitChanges = async () => {
    const { id, privacy, storyId, position } = props;

    if (!isEmpty(parseTextContent(value))) {
      if (includes(id, "text-editor")) {
        await dispatch(
          blockAdd({
            storyId: storyId,
            type: "text",
            subType: "rich-text",
            content: { value: parseTextContent(value) },
            position: position,
          })
        );
      } else {
        await dispatch(
          blockUpdate({
            storyId: storyId,
            blockId: id,
            content: {
              value: parseTextContent(value),
            },
          })
        );
      }

      if (privacy === "public" && storyTextBlockCount === 1) {
        props.openPrivacyModal();
      }
    }
  };

  const finishEditing = () => {
    submitChanges();
    props.doneEditing();
  };

  const cancel = () => {
    dispatch(cancelAddTextBlock(props.id));
  };

  const displayLinkModal = useCallback(() => {
    setLinkModal(true);

    const previousUrl = editor.getAttributes("link").href;
    const previousChecked = editor.getAttributes("link").target;

    if (previousUrl) {
      setLinkHref(previousUrl);
    }

    if (previousChecked == "_blank") {
      setLinkExternal(true);
    }
  }, [editor, setLinkModal]);

  const displayInlinePrivacyReminder = props.privacy === "public";

  return (
    <CloseOnEscape onEscape={() => finishEditing()}>
      <LinkModal
        linkModal={linkModal}
        editor={editor}
        setLinkModal={setLinkModal}
        linkHref={linkHref}
        setLinkHref={setLinkHref}
        linkExternal={linkExternal}
        setLinkExternal={setLinkExternal}
      />

      <DeleteTextModal
        id={props.id}
        storyId={props.storyId}
        opened={modalOpened}
        setOpened={setModalOpened}
      />

      {displayInlinePrivacyReminder && (
        <p className="story-blocks__text-editor-container__inline-privacy-reminder">
          You are editing a publicly available story
        </p>
      )}

      <div className="tip-tap">
        <div className="button-group float-left margin-right-1">
          <button
            onClick={() => {
              editor.chain().focus().toggleBold().run();
            }}
            className={
              editor?.isActive("bold")
                ? "tip-tap__button tip-tap__button--active button"
                : "button tip-tap__button"
            }
            title="bold"
          >
            <i className="fa fa-bold" aria-hidden="true"></i>
          </button>

          <button
            onClick={() => {
              editor.chain().focus().toggleItalic().run();
            }}
            className={
              editor?.isActive("italic")
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            title="italic"
          >
            <i className="fa fa-italic" aria-hidden="true"></i>
          </button>

          <button
            onClick={() => {
              editor.chain().focus().toggleMark("underline").run();
            }}
            className={
              editor?.isActive("underline")
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            title="underline"
          >
            <i className="fa fa-underline" aria-hidden="true"></i>
          </button>
        </div>

        <div className="button-group float-left margin-right-1">
          <button
            className={
              editor?.isActive("bulletList")
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            onClick={() => {
              editor.chain().focus().toggleBulletList().run();
            }}
            title="bullet list"
          >
            <i className="fa fa-list-ul" aria-hidden="true"></i>
          </button>

          <button
            className={
              editor?.isActive("orderedList")
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            onClick={() => {
              editor.chain().focus().toggleOrderedList().run();
            }}
            title="numbered list"
          >
            <i className="fa fa-list-ol" aria-hidden="true"></i>
          </button>

          <button
            className={
              editor?.isActive("blockquote")
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            title="blockquote"
          >
            <i className="fa fa-quote-right" aria-hidden="true"></i>
          </button>
        </div>

        <div className="button-group float-left margin-right-1">
          <button
            onClick={() => {
              displayLinkModal();
            }}
            className={
              editor?.isActive("link")
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            title="link"
          >
            <i className="fa fa-link" aria-hidden="true"></i>
          </button>

          <button
            onClick={() => editor.chain().focus().unsetLink().run()}
            className="tip-tap__button  button"
            title="unlink"
          >
            <i className="fa fa-chain-broken" aria-hidden="true"></i>
          </button>
        </div>

        <div className="button-group float-left margin-right-1">
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            className={
              editor?.isActive("heading", { level: 1 })
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            title="heading 1"
          >
            h1
          </button>

          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor?.isActive("heading", { level: 2 })
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            title="heading 2"
          >
            h2
          </button>

          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor?.isActive("heading", { level: 3 })
                ? "button tip-tap__button tip-tap__button--active"
                : "button tip-tap__button"
            }
            title="heading 3"
          >
            h3
          </button>
        </div>

        <div className="button-group float-left">
          <button
            onClick={() => editor.chain().focus().undo().run()}
            className="button tip-tap__button"
            title="undo"
          >
            <i className="fa fa-undo" aria-hidden="true"></i>
          </button>

          <button
            onClick={() => editor.chain().focus().redo().run()}
            className="button tip-tap__button"
            title="redo"
          >
            <i className="fa fa-repeat" aria-hidden="true"></i>
          </button>
        </div>

        <div className="clearfix"></div>

        <EditorContent editor={editor} />
      </div>

      {!includes(props.id, "text-editor") && (
        <div className="float-left margin-top-1">
          <button
            onClick={() => setModalOpened(true)}
            aria-label="delete text block"
            className="button clear"
          >
            Delete
          </button>
        </div>
      )}

      <div className="float-right margin-top-1">
        <button
          onClick={() => cancel()}
          aria-label="Close editor"
          className="button clear"
        >
          Cancel
        </button>

        <button
          disabled={disableSave}
          onClick={() => finishEditing()}
          aria-label="Save and close editor"
          className="button"
        >
          Save
        </button>
      </div>
    </CloseOnEscape>
  );
};
