import classNames from "classnames";
import { assign } from "lodash";
import React from "react";
import { useDrop } from "react-dnd";
import { updateStoryBlocks } from "src/features/ItemsSlice";
import { updateActiveSlot } from "src/features/EditStorySlice";
import Block from "./block";
import dropBlock from "./storyLogic/dropBlock";
import { onTheFarLeft } from "./storyLogic/utility";

const Slot = (props) => {
  const {
    content,
    findBlockIndex,
    editable,
    hasLargeBlock,
    storyId,
    rowId,
    blocks,
    activeSlot,
    dispatch,
    id,
  } = props;

  const [, dropRef] = useDrop(
    () => ({
      accept: "SLOT",
      drop: (dragSourceItem) => {
        const storyBlocks = dropBlock(blocks, props, dragSourceItem);

        dispatch(updateStoryBlocks(storyBlocks));
        dispatch(updateActiveSlot({ id: "" }));
      },
      hover: (item) => {
        if (activeSlot.rowId === rowId && activeSlot.slotId === id) return null;

        let blockSize = "small",
          adjacentRowId = "";
        if (item.meta.alignMode === 1) {
          blockSize = "large";
          if (!onTheFarLeft(id + 1)) {
            adjacentRowId = rowId + 1;
          }
        }

        dispatch(
          updateActiveSlot({ rowId, blockSize, adjacentRowId, slotId: id })
        );
      },
    }),
    [props]
  );

  const dropClasses = classNames({
    "slot--drop-left":
      activeSlot.rowId === rowId &&
      activeSlot.slotId === id &&
      content.status !== "empty",
    "slot--drop-empty":
      activeSlot.rowId === rowId &&
      activeSlot.slotId === id &&
      content.status === "empty",
  });

  let insiderContent = null,
    outerClasses = null;

  const unavailableStory = ["deleted", "suppressed"].includes(
    content?.content?.status
  );
  if (content.id != undefined) {
    if (!editable && unavailableStory) return null;

    const isSmallBlock = content.meta.alignMode === 0;

    const sizeClasses = classNames({
      "small-12": true,
      "medium-4": isSmallBlock,
      "medium-12": !isSmallBlock,
    });

    const blockClasses = classNames({
      "story-blocks--small": isSmallBlock,
      "story-blocks--large": !isSmallBlock,
    });

    outerClasses = `slot ${sizeClasses} ${dropClasses}`;
    insiderContent = (
      <div
        data-animate="fade-in fade-out"
        className={`cell float-left ${blockClasses}`}
        id={`story-block-${content.id}`}
      >
        <Block
          {...assign({}, content, { findBlockIndex })}
          editing={editable && content.id === props.activeTextItem}
          activeTextItem={props.activeTextItem}
          modifiable={editable}
          viewMode={"stack"}
          user={props.user}
          activeRecordItem={props.activeRecordItem}
          storyTextBlockCount={props.storyTextBlockCount}
          privacy={props.metadata.privacy}
          openPrivacyModal={props.openPrivacyModal}
          dispatch={dispatch}
          storyId={storyId}
          rowId={rowId}
          slotId={id}
        />
      </div>
    );
  } else if (content.status === "empty") {
    if (hasLargeBlock) return null;

    const emptyClasses = classNames({
      slot__drop_target: editable,
      "slot--empty": editable,
    });

    outerClasses = "medium-4 slot show-for-medium";
    insiderContent = <div className={`${dropClasses} ${emptyClasses}`}></div>;
  }

  return (
    <div ref={dropRef} className={outerClasses}>
      {insiderContent}
    </div>
  );
};

export default Slot;
