import { find, findIndex, map, filter } from "lodash";
import {
  smallBlock,
  largeBlock,
  emptyBlock,
  inTheMiddle,
  onTheFarRight,
  onTheFarLeft,
  correctPositions,
} from "./utility";
import storyBlocks from "./storyBlocks";

const replaceMovedBlockWithEmptyBlock = (blocks, dragSource) => {
  return map(blocks, (block) => {
    if (block.id === dragSource.id) {
      return emptyBlock(block.position);
    } else {
      return block;
    }
  });
};

// If a large block is dropped in a location that is not the far left
// We look ahead to first confirm that it's not allready there
// Before proceeding with the sorting.
const alreadyInPosition = (dragSource, position) => {
  if (largeBlock(dragSource)) {
    if (!onTheFarLeft(position)) {
      let distance = 0;
      // If it ends up going back where it was, do nothing.
      if (inTheMiddle(position)) {
        distance = 2;
      } else if (onTheFarRight(position)) {
        distance = 1;
      }

      return position + distance == dragSource.position;
    }
  }
};

const droppingOnSelf = (dropTarget, dragSource) => {
  return dropTarget.content.id == dragSource.id;
};

// When a large block is dropped we need to insert the small empty blocks that
// Complete the row.
const completeLargeBlockRow = (dragSource, position, blocks, newIndex) => {
  let blocksCopy = [...blocks];

  if (largeBlock(dragSource)) {
    if (onTheFarLeft(position)) {
      // Only insert empty blocks if there aren't any allready there

      const emptyBlockOne = newIndex + 1;
      const emptyBlockTwo = newIndex + 2;

      if (
        blocksCopy[emptyBlockOne] == undefined ||
        blocksCopy[emptyBlockOne].status != "empty"
      ) {
        blocksCopy.splice(emptyBlockOne, 0, emptyBlock(position + 1));
      }

      if (
        blocksCopy[emptyBlockTwo] == undefined ||
        blocksCopy[emptyBlockTwo].status != "empty"
      ) {
        blocksCopy.splice(emptyBlockTwo, 0, emptyBlock(position + 2));
      }
    } else if (inTheMiddle(position)) {
      const emptyBlockOne = newIndex + 1;
      if (
        blocksCopy[emptyBlockOne] == undefined ||
        blocksCopy[emptyBlockOne].status != "empty"
      ) {
        blocksCopy.splice(emptyBlockOne, 0, emptyBlock(position + 1));
      }
    }
  }

  return blocksCopy;
};

// This is for a particular scenario which causes blocks that are
// Seperated by gaps to be moved around as there is an extra block that we don't actually want.
const removeExcessBlocks = (
  blocks,
  dragSource,
  position,
  dropTarget,
  newIndex
) => {
  let blocksCopy = [...blocks];

  // Look ahead for an extra empty block and remove it, so as to not
  // push the blocks seperated by gaps around if we are moving forward
  if (
    (dragSource.position < position &&
      dropTarget.content.status != "empty" &&
      smallBlock(dragSource)) ||
    (dropTarget.content.status != "empty" &&
      smallBlock(dragSource) &&
      largeBlock(dropTarget.content))
  ) {
    const blockToRemove = findIndex(
      blocksCopy,
      (block) => {
        return block.status == "empty";
      },
      newIndex
    );

    if (blockToRemove !== -1) {
      blocksCopy.splice(blockToRemove, 1);
    }
  }

  return blocksCopy;
};

const smallBlockMovingBackwards = (dragSource, dropTarget) => {
  return (
    dragSource.position > dropTarget.content.position &&
    dropTarget.content.status != "empty" &&
    smallBlock(dropTarget.content) &&
    smallBlock(dragSource)
  );
};

//
// Drop
//

export default function dropBlock(blocks, dropTarget, dragSource) {
  const position = dropTarget.content.position;

  if (
    droppingOnSelf(dropTarget, dragSource) ||
    alreadyInPosition(dragSource, position)
  ) {
    return blocks;
  }

  const blockToMove = find(blocks, (b) => b.id === dragSource.id);

  // If a small block is moving backwards the behaviour is to swap,
  // Rather than push.
  if (smallBlockMovingBackwards(dragSource, dropTarget)) {
    blocks = filter(blocks, (block) => {
      return block.id != blockToMove.id;
    });
  } else {
    blocks = replaceMovedBlockWithEmptyBlock(blocks, dragSource);
  }

  const newIndex = position - 1;

  // If you are dropping a block onto an empty space
  // Replace the empty block with the new block.
  if (dropTarget.content.status === "empty") {
    blocks.splice(newIndex, 1, blockToMove);
  } else {
    // Otherwise insert the new block and push everyone across.
    blocks.splice(newIndex, 0, blockToMove);
  }

  blocks = removeExcessBlocks(
    blocks,
    dragSource,
    position,
    dropTarget,
    newIndex
  );
  blocks = completeLargeBlockRow(dragSource, position, blocks, newIndex);
  blocks = correctPositions(blocks);

  return storyBlocks(blocks);
}
