import React from "react";
import { useDispatch } from "react-redux";
import { toggleFacetTab, clearFiltersByFacet } from "src/features/SearchSlice";
import Analytics from "src/utils/Analytics";

const FacetFilterActionBar = (props) => {
  const { hasActiveFilters, hasDifferentFilters, facetName, performSearch } =
    props;

  const dispatch = useDispatch();

  const clearFilters = (facetName) => {
    Analytics.event("click", "SearchFilterSelect", "Clear " + facetName);
    dispatch(clearFiltersByFacet(facetName));
  };

  const applyFilters = () => {
    Analytics.event("click", "SearchFilterSelect", "Apply filters");
    performSearch();
  };

  return (
    <div className="search-panel__content__action-bar padding-vertical-1">
      <div className="clear-all">
        {hasActiveFilters && (
          <button
            className="button clear"
            onClick={() => clearFilters(facetName)}
          >
            Clear all
            <i className="fa fa-close end" aria-hidden="true"></i>
          </button>
        )}
      </div>

      {!hasDifferentFilters && (
        <button
          className="button clear"
          onClick={() => dispatch(toggleFacetTab())}
        >
          Close
          <i className="fa fa-close end" aria-hidden="true"></i>
        </button>
      )}

      {hasDifferentFilters && (
        <button className="button" onClick={applyFilters}>
          Apply filters
          <i className="fa fa-chevron-right end" aria-hidden="true"></i>
        </button>
      )}
    </div>
  );
};

export default FacetFilterActionBar;
