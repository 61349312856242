import React, { Component } from "react";
import { connect } from "react-redux";
import SearchResults from "src/components/SearchPage/components/search/SearchResults";
import SearchTab from "./components/searchTab/index";
import NoResults from "src/components/SearchPage/components/search/NoResults";
import { Helmet } from "react-helmet";
import generateTitleText from "src/utils/generateTitleText";
import {
  toggleError,
  toggleFacetTab,
  setMobileFiltersState,
} from "src/features/SearchSlice";
import SearchBox from "src/components/SearchPage/components/searchBox/SearchBox";
import generateSearchParams from "src/utils/generateSearchParams";
import MobileFilters from "src/components/MobileFilters/index";
import DesktopFilter from "src/components/DesktopFilter/index";
import { bindAll, replace, find, range, includes, isEmpty } from "lodash";
import AddToStoryModalBehaviour from "src/components/AddToStoryModalBehaviour";
import { selectRecordById } from "src/features/RecordsSlice";
import Analytics from "src/utils/Analytics";
import AdvancedSearch from "./components/searchBox/AdvancedSearch";

class SearchPage extends Component {
  constructor(props) {
    super(props);

    if (props.ui.search.resultsIds === undefined) {
      location.reload();
      return;
    }

    this.state = {
      // searchMode: "advanced",
      searchMode: "simple",
    };

    this.results = props.ui.search.resultsIds.map((id) =>
      selectRecordById(props, id)
    );
    bindAll(
      this,
      "switchSearchMode",
      "handleSubmit",
      "performSearch",
      "validDateRangeFilter"
    );
  }

  componentDidMount() {
    // This sneaky code gets the browser to auto scroll to the id the user was viewing
    // we strip the slash so that it plays nice with the mobile filters router.
    const item_id = replace(location.hash, "/", "");

    location.hash = "";

    // TODO the transition seems to be too fast for the render.
    setTimeout(function () {
      location.hash = item_id;
    }, 10);
  }

  titleText() {
    let filters = this.props.ui.search.filters.slice();
    let searchTab;

    if (this.props.ui.search.activeTab !== "All") {
      searchTab = this.props.ui.search.activeTab;
    }

    return generateTitleText(
      this.props.ui.search.query,
      "SEARCH",
      filters,
      searchTab
    );
  }

  switchSearchMode(e) {
    e.preventDefault();

    this.setState({
      searchMode: this.state.searchMode === "simple" ? "advanced" : "simple",
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    Analytics.event(
      "Search",
      "Search Box",
      $(event.target).find("input").filter(":visible:first").val()
    );

    this.performSearch();
  }

  validDateRangeFilter() {
    const dateRange = find(this.props.ui.search.filters, (filter) => {
      return filter.facet == "date_range";
    });

    const validDates = range(1, new Date().getFullYear() + 1);
    let errors = [];

    if (dateRange) {
      const { dispatch } = this.props;

      const years = dateRange.value.split(" TO ");
      const fromYear = parseInt(years[0]);
      const toYear = parseInt(years[1]);

      if (!fromYear || !includes(validDates, fromYear)) {
        errors.push("from");
        dispatch(toggleError({ field: "from", value: true }));
      } else {
        dispatch(toggleError({ field: "from", value: false }));
      }

      if (!toYear || !includes(validDates, toYear)) {
        errors.push("to");
        dispatch(toggleError({ field: "to", value: true }));
      } else {
        dispatch(toggleError({ field: "to", value: false }));
      }

      if (fromYear > toYear) {
        errors.push("range");
        dispatch(toggleError({ field: "range", value: true }));
      } else {
        dispatch(toggleError({ field: "range", value: false }));
      }
    }

    return isEmpty(errors);
  }

  performSearch() {
    const urlWithoutQueryParams = window.location.host + "/records?";
    const searchParams = generateSearchParams(
      this.props.ui.search.query,
      this.props.ui.search.filters,
      this.props.ui.search.activeTab,
      1
    );
    const { dispatch } = this.props;

    if (this.validDateRangeFilter()) {
      $(".spinner").show();
      // This uses the jquery param method to convert an object into properly
      // url encoded query parameters
      const newUrl = "http://" + urlWithoutQueryParams + $.param(searchParams);
      window.location.assign(newUrl);
    } else {
      if (this.props.ui.search.mobileFiltersState == "closed") {
        if (this.props.ui.search.panel.activeFacetTab != "date_range") {
          dispatch(toggleFacetTab("date_range"));
        }
      } else {
        if (this.props.ui.search.mobileFiltersState != "date_range") {
          dispatch(setMobileFiltersState("date_range"));
        }
      }
    }
  }

  render() {
    if (this.props.ui.search.resultsIds === undefined) return;

    if (this.props.ui.search.apiAvailable) {
      return (
        <>
          <Helmet>
            <title>{this.titleText()}</title>
          </Helmet>

          <div className="search-container">
            {this.props.ui.search.mobileFiltersState == "closed" &&
              ((this.state.searchMode === "simple" && (
                <SearchBox
                  handleSubmit={this.handleSubmit}
                  switchSearchMode={this.switchSearchMode}
                />
              )) || <AdvancedSearch />)}
            <SearchTab />

            {this.results.length > 0 && (
              <MobileFilters
                performSearch={this.performSearch}
                validDateRangeFilter={this.validDateRangeFilter}
              />
            )}
          </div>

          {this.props.ui.search.mobileFiltersState != "closed" && (
            <div className="mobile-filters-background"></div>
          )}

          {this.results.length > 0 && (
            <DesktopFilter performSearch={this.performSearch} />
          )}
          {this.results.length > 0 &&
            this.props.ui.search.mobileFiltersState == "closed" && (
              <SearchResults />
            )}

          {this.results.length === 0 && (
            <NoResults
              categories={[
                ...this.props.ui.search.primaryCategoryCounts,
                ...this.props.ui.search.secondaryCategoryCounts,
              ]}
              activeCategory={this.props.ui.search.activeTab}
              currentSearch={this.props.ui.search.query}
              filters={this.props.ui.search.filters}
            />
          )}
          <AddToStoryModalBehaviour />
        </>
      );
    } else {
      return (
        <div className="grid-container">
          <strong>
            Sorry, search is not working at the moment. Someone has been
            notified. Please try again later.
          </strong>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps)(SearchPage);
