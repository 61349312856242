import React from "react";
import { map, truncate } from "lodash";

const StoryCheckboxes = ({ stories, record, storiesMap, onStoryToggle }) => {
  return map(stories, ({ id, name, numberOfItems, recordIds }) => {
    const toggleCheckbox = onStoryToggle.bind(null, id);

    return (
      <div key={id} className="grid-x grid-margin-x">
        <div className="cell auto">
          <label className="story-checkboxes__label">
            <input
              type="checkbox"
              className="story-checkboxes__checkbox"
              checked={storiesMap[id]}
              onChange={toggleCheckbox}
              aria-label={`Close menu and add this item to story ${name}`}
            />
            {truncate(unescape(name), { length: 100 })}
          </label>
        </div>
        <div className="cell shrink">
          <a
            className="story-checkboxes__item-number"
            href={`/stories/${id}`}
            target={"_blank"}
            aria-label={`There are ${numberOfItems} items in this story. Click to view story ${name}`}
          >
            {`${numberOfItems} items`}
          </a>
        </div>
      </div>
    );
  });
};

export default StoryCheckboxes;
