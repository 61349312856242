import React from "react";
import { Provider } from "react-redux";
import { StaticRouter } from "react-router-dom/server.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RecordPage from "src/components/RecordPage";
import configureAppStore from "src/store";
import { recordsReducer } from "src/reducers";
import ErrorBoundary from "src/components/ErrorBoundary";
import inBrowser from "src/utils/inBrowser";
import EditUserImageModal from "src/components/RecordCard/EditUserImageModal";
import SearchPage from "src/components/SearchPage/SearchPage";

const RecordPageApp = (props) => {
  // the router is required here to use `useLocation` in children
  const Router = inBrowser() ? BrowserRouter : StaticRouter;

  return (
    <ErrorBoundary>
      <Provider store={configureAppStore(recordsReducer, props)}>
        <Router location={props.ui.appDetails.location}>
          <Routes>
            <Route path="/records" element={<SearchPage />} />
            <Route path="/records/:id" element={<RecordPage />} />
            <Route path="/records/:id/*" element={<RecordPage />} />
          </Routes>
        </Router>
        <EditUserImageModal />
      </Provider>
    </ErrorBoundary>
  );
};

export default RecordPageApp;
