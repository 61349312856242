import React from "react";
import { FacebookProvider, Comments } from "react-facebook";

function RecordFacebookComments({ facebookCommentsUrl, facebookAppId }) {
  if (process.env.RAILS_ENV == "test") {
    return null;
  }

  return (
    <FacebookProvider appId={facebookAppId}>
      <Comments href={facebookCommentsUrl} />
    </FacebookProvider>
  );
}

export default RecordFacebookComments;
