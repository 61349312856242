import React from "react";
import SocialMediaLinks from "src/components/SocialMediaLinks";

const BaseLayout = ({ Header, children, id }) => {
  return (
    <div className="my-stories">
      <div className={"my-stories__stories-container"}>
        {Header}
        {children}
        {id !== undefined && (
          <div className="grid-container">
            <div className="my-stories__stories-container__social-media">
              <SocialMediaLinks id={id} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BaseLayout;
