import React from "react";
import ExperimentalBlock from "./ExperimentalBlock";

const Entities = ({ record }) => {
  return (
    <ExperimentalBlock
      title="Entities"
      entities={[
        {
          title: "People",
          tags: record.autoTagPeople,
          solrAttr: "autotag_people",
        },
        {
          title: "Places",
          tags: record.autoTagPlaces,
          solrAttr: "autotag_places",
        },
        {
          title: "Organisations",
          tags: record.autoTagOrganisations,
          solrAttr: "autotag_organisations",
        },
        {
          title: "Events",
          tags: record.autoTagEvents,
          solrAttr: "autotag_events",
        },
      ]}
      description={
        <p>
          <em>
            Entities automatically pull out specific concepts from existing
            metadata (using the records title and description).{" "}
            <a href="#">Learn more about how these entities are created</a>
          </em>
        </p>
      }
    />
  );
};

export default Entities;
