import React from "react";
import ReactDOM from "react-dom";
import { filter, includes, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlwaysAdd } from "src/features/AddToStorySlice";
import { closeNotification } from "src/features/AddToStorySlice";
import AddToStoryDescription from "src/components/AddToStoryDescription";
import classNames from "classnames";
import inBrowser from "src/utils/inBrowser";
import { selectAllStories } from "src/features/StoriesSlice";
import { selectAddToStory } from "src/features/AddToStorySlice";

const AddToStoryNotification = () => {
  const dispatch = useDispatch();
  const stories = useSelector(selectAllStories);
  const {
    alwaysAddToStory,
    storyIdsToAlwaysAdd,
    notificationOpened,
    notificationNumber,
  } = useSelector(selectAddToStory);

  if (!inBrowser()) return null;

  const addedStories = filter(stories, (story) =>
    includes(storyIdsToAlwaysAdd, story.id)
  );
  const storiesButtonText =
    storyIdsToAlwaysAdd.length > 1 ? "these stories" : "this story";
  const buttonText = alwaysAddToStory
    ? `Don't always add to ${storiesButtonText} `
    : `Always add to ${storiesButtonText} `;

  const divClass = classNames(
    "flash-message",
    "ats-notification",
    notificationOpened
      ? "ats-notification--fade-in"
      : "ats-notification--fade-out",
    { [`fade-out-and-in-${notificationNumber}`]: notificationNumber != null }
  );
  return ReactDOM.createPortal(
    <div onClick={() => dispatch(closeNotification())} className={divClass}>
      <div className="callout success float-center">
        <div className="grid-x align-middle">
          <div className="cell small-12 large-auto ats-notification__content">
            <i
              className="fa fa-check-circle ats-notification__intro-icon"
              aria-hidden="true"
            ></i>{" "}
            <AddToStoryDescription
              preContent="Added to"
              storyNames={map(addedStories, "name")}
            />
          </div>
          <div className="cell small-12 large-shrink">
            <button
              className="button ats-notification__button"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(toggleAlwaysAdd());
              }}
            >
              {buttonText}
            </button>
          </div>
          <div className="cell shrink">
            <button
              className="close-button"
              aria-label="Dismiss alert"
              type="button"
            >
              <i className="fa fa-close" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("notifications") // defined in application.html.haml
  );
};

export default AddToStoryNotification;
