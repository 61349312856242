import React, { Component } from "react";
import { request } from "src/utils/request";
import generateTitleText from "src/utils/generateTitleText";
import TextForm from "./TextForm";
import RightsForm from "./RightsForm";
import TopicExplorerForm from "./TopicExplorerForm";
import Modal from "src/components/Modal";
import { Helmet } from "react-helmet";
import CopyrightLicence from "../../../storyheader/CopyrightLicence";
import { without } from "lodash";
import { bindAll, concat, includes } from "lodash";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.tags,
      deleteModal: false,
      helpModal: false,
    };

    bindAll(
      this,
      "deleteStory",
      "convertSubjectsToArray",
      "toggleS2S",
      "closeModal",
      "openModal"
    );
  }

  componentDidMount() {
    const accessForm = new Foundation.Reveal($("#access-form"));
    const deleteForm = new Foundation.Reveal($("#delete-form"));
    const helpModal = new Foundation.Reveal($("#help-modal"));
    accessForm;
    deleteForm;
    helpModal;
  }

  convertSubjectsToArray(subjects) {
    const subjectsArray = subjects.split(",").map((subject) => subject.trim());
    this.props.storyMetadataUpdateCb("subjects", subjectsArray);
  }

  openModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  closeModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  deleteStory() {
    const { id } = this.props;
    const url = `/api/stories/${id}`;

    request({ url: url, options: { method: "delete" } }).then((json) => {
      if (json.status === 200) window.location.assign("/stories");
      else {
        this.setState({
          deleteStoryMessage: "Deleting story failed",
          deleteStoryIcon: "fa fa-ban",
        });
      }
    });
  }

  toggleS2S(e, field) {
    const cb = this.props.storyMetadataUpdateCb;
    const tags = this.state.tags;

    if (e.target.checked)
      this.setState({ tags: concat(tags, field) }, () =>
        cb("tags", this.state.tags)
      );
    else
      this.setState({ tags: without(tags, field) }, () =>
        cb("tags", this.state.tags)
      );
  }

  render() {
    const {
      description,
      subjects,
      privacy,
      storyMetadataUpdateCb,
      user,
      name,
      state,
      storyTextBlockCount,
    } = this.props;

    const { tags, deleteModal, helpModal } = this.state;

    const isAdmin = user.loggedIn && includes(user.roles, "admin");
    const isSchoolAdmin = user.loggedIn && includes(user.roles, "school_admin");
    const searchParams = new URLSearchParams({ search: name });

    return (
      <header className="story-header-edit">
        <Helmet>
          <title>{generateTitleText(name, "STORY")}</title>
        </Helmet>

        <div className="story-header-edit__content grid-container">
          <div className="story-header-edit__content__form" role="form">
            <div className="story-header-edit__content__title">
              <label
                className="story-header-edit__content__label"
                htmlFor="story-name"
                id="story-title-label"
              >
                Story title
              </label>
              <TextForm
                aria-labelledby="story-title-label"
                className="d-print-none"
                id={"story-name"}
                maxLength="250"
                value={name}
                placeholder={"Give your story a name"}
                updateCb={storyMetadataUpdateCb.bind(null, "name")}
              />
              <p className="hide d-print-block">{name}</p>
            </div>

            <label
              className="story-header-edit__content__label"
              htmlFor="story-description"
            >
              Story description
            </label>
            <TextForm
              id={"story-description"}
              className="d-print-none"
              maxLength={isSchoolAdmin ? "700" : "250"}
              value={description}
              placeholder={"Add a description here"}
              updateCb={storyMetadataUpdateCb.bind(null, "description")}
            />
            <p className="hide d-print-block">{description}</p>

            <label
              className="story-header-edit__content__label"
              htmlFor="story-subjects"
            >
              Story subjects
            </label>
            <TextForm
              id={"story-subjects"}
              className="d-print-none"
              maxLength="250"
              value={subjects.join(", ")}
              placeholder={"Separate subjects with a comma"}
              updateCb={this.convertSubjectsToArray}
            />
            <p className="hide d-print-block">{subjects.join(", ")}</p>

            <RightsForm
              privacy={privacy}
              storyTextBlockCount={storyTextBlockCount}
              updateCb={storyMetadataUpdateCb}
              state={state}
              name={name}
              numberOfItems={this.props.numberOfItems}
            />

            {isAdmin && (
              <a
                className="story-header-edit__content__link"
                href={`/admin/stories?${searchParams}`}
              >
                Moderate
              </a>
            )}
            {isSchoolAdmin && TopicExplorerForm(tags, this.toggleS2S)}
          </div>

          <div className="story-header-edit__help-share grid-x grid-margin-y padding-top-2 d-print-none">
            <div className="cell medium-4 medium-order-2 medium-text-center">
              <CopyrightLicence />
            </div>

            <div className="cell small-6 medium-4 medium-order-1">
              <button
                className="button white hollow"
                id="story-help"
                onClick={() => this.openModal("helpModal")}
              >
                <span>Quick help</span>
              </button>

              <Modal
                isOpen={helpModal}
                onRequestClose={() => this.closeModal("helpModal")}
                className="generic-modal modal--wide"
                title="Quick help"
              >
                <div className="generic-modal__content">
                  <p>
                    Stories allow you to save items found in DigitalNZ, upload
                    your own images and add your own captions, titles, and notes
                    to tell a story.
                  </p>
                  <h3>How to customise your story</h3>
                  <p>
                    <strong>Drag and drop - </strong>
                    By default, your items will be added as you add things. You
                    can reorder your items and customise your story by drag and
                    drop the item cards and text boxes.
                  </p>
                  <p>
                    <strong>Add text - </strong>
                    You can add text by tapping on the "add text" button which
                    will expand to be editable.
                  </p>
                  <p>
                    <strong>Manage/change access - </strong>
                    Your story is in moderation once created and will be made
                    Public if approved. You can manage/change privacy access
                    once you add items to your story.
                  </p>
                  <p>
                    <a href="/help">Go here for more help.</a>
                  </p>
                </div>
                <hr />
                <div className="generic-modal__actions">
                  <button
                    className="button"
                    type="button"
                    onClick={() => this.closeModal("helpModal")}
                  >
                    Got it
                  </button>
                </div>
              </Modal>
            </div>

            <div className="cell small-6 medium-4 medium-order-3 text-right">
              <button
                className="button white hollow"
                id="story-delete"
                aria-label="Delete this story"
                onClick={() => this.openModal("deleteModal")}
              >
                Delete
              </button>
              <Modal
                isOpen={deleteModal}
                onRequestClose={() => this.closeModal("deleteModal")}
                className="generic-modal"
                title="Are you sure you want to delete this story?"
              >
                <div className="generic-modal__content">
                  <p>
                    Once you delete this story you will not be able to recover
                    it.
                  </p>
                </div>
                <hr />
                <div className="generic-modal__actions">
                  <button
                    className="button clear"
                    aria-label="Close modal"
                    id="dont-delete-story-delete-form"
                    onClick={() => this.closeModal("deleteModal")}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    aria-label="Close modal"
                    onClick={() => this.deleteStory()}
                    id="delete-story"
                  >
                    Delete
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
