import React, { useState } from "react";
import { includes } from "lodash";
import { useDispatch } from "react-redux";
import { blockRemove } from "src/features/ItemsSlice";

import Modal from "src/components/Modal";

const DeleteTextModal = ({ id, storyId, opened, setOpened }) => {
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);

  const deleteTextBlock = async (id) => {
    setDeleting(true);

    if (!includes(id, "text-editor")) {
      await dispatch(blockRemove({ storyId: storyId, blockId: id }));
    }

    setDeleting(false);
    setOpened(false);
  };

  return (
    <Modal
      isOpen={opened}
      onRequestClose={() => (!deleting ? setOpened(false) : null)}
      className="share-modal generic-modal"
      title="Delete text block"
    >
      <div className="generic-modal__content">
        <p>
          Are you use you want to delete your text box? You cannot undo this
          action.
        </p>
      </div>
      <hr />
      <div className="generic-modal__actions">
        <button
          className="button clear"
          aria-label="Cancel"
          onClick={() => setOpened(false)}
          disabled={deleting}
        >
          Cancel
        </button>
        <button
          className="button"
          aria-label="Remove item"
          onClick={() => deleteTextBlock(id)}
          disabled={deleting}
        >
          <i
            className={`fa fa-circle-o-notch fa-spin start ${
              deleting ? "show" : "hide"
            }`}
          ></i>
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteTextModal;
