require("isomorphic-fetch");
require("es6-promise/auto");

import React from "react";
import { Provider } from "react-redux";

import { StaticRouter } from "react-router-dom/server.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SearchPage from "src/components/SearchPage/SearchPage";

import { ShowStory, MyStories } from "src/components/StoriesPage/pages";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import inBrowser from "src/utils/inBrowser";
import ErrorBoundary from "src/components/ErrorBoundary";

import configureAppStore from "src/store";
import { recordsReducer } from "src/reducers";
import EditUserImageModal from "src/components/RecordCard/EditUserImageModal";

const App = (props) => {
  const location = props.ui.appDetails.location;
  const Router = inBrowser() ? BrowserRouter : StaticRouter;

  // We handle /records here
  // As if the user clicks on a story from the search results page, then clicks on a record.
  // If they use the browser back button to get back to the Search Results page.
  // The stories app is still mounted and it breaks as it doesnt know how to handle /records.

  return (
    <Provider store={configureAppStore(recordsReducer, props)}>
      <Router location={location} context={{}}>
        <Routes>
          <Route path="/stories" element={<MyStories />} />
          <Route path="/stories/:id" element={<ShowStory />} />
          <Route path="/records" element={<SearchPage />} />
        </Routes>
      </Router>
      <EditUserImageModal />
    </Provider>
  );
};

const StoriesApp = (props) => {
  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <App {...props} />
      </DndProvider>
    </ErrorBoundary>
  );
};

export default StoriesApp;
