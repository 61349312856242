import React from "react";
import HorizontalRecordCard from "./HorizontalRecordCard";
import VerticalRecordCard from "./VerticalRecordCard";

const RecordCard = ({ layout, ...props }) => {
  const RecordComponent =
    layout === "list" ? HorizontalRecordCard : VerticalRecordCard;

  return <RecordComponent {...props} />;
};

export default RecordCard;
