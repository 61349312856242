import React from "react";
import ExperimentalBlock from "./ExperimentalBlock";

const AutoTags = ({ record }) => {
  return (
    <ExperimentalBlock
      title="Auto-tags"
      entities={[
        { title: "Auto-tags", tags: record.autoTag, solrAttr: "auto_tag" },
      ]}
      description={
        <p>
          <em>
            These tags are automatically generated by computer programs that
            analyse the visual contents of this image.{" "}
            <a href="#">Learn more about how these entities are created</a>
          </em>
        </p>
      }
    />
  );
};

export default AutoTags;
