import { last, each, range, first, isNil } from "lodash";

import { smallBlock, largeBlock, inCorrectPosition } from "./utility";

// Converts an array of blocks into an array of positions.
// This adds all the padding that is created by large blocks and sanitizes the input from the API.
// The data from the API will not necessarily have items in positions where they are actually allowed to be
// and it will also not have the empty blocks.
//
// The intention of this is to avoid any sort of infinite loop when the story blocks are being created
// as the number of items in a story or the last position of a story is not necessarily the number of blocks we need and using a while loop
// feels risky.
// For example if we have 4 blocks, 3 large and 1 small, we actually have 12 positions as the large blocks all take up their own
// row and their are 2 empty ones that need to complete the first row. Old data from the API will notate this as positions 1, 2, 3, and 4.
// Which is incorrect.
//
// This logic is essentially the same as in the stories redux presenter, but is here as well to avoid the clientside having to query the rails app
// whenever an item is moved.
//
// == Parameters:
// blocks::
//   An array of JSON objects that have been retrieved from the storys API.
//
// == Returns:
// An array of integers, representing the positions that are available in the story.
//
export default function positions(blocks) {
  let blocksCopy = [...blocks];
  let finalPosition = 0;

  let maxPositions = blocksCopy.length;
  maxPositions += last(blocks).position;

  // If the total of not empty blocks is not a factor of 3. Round it up to the nearest 3.
  if (maxPositions % 3 != 0) {
    maxPositions += 3 - (maxPositions % 3);
  }

  each(range(1, maxPositions), (position) => {
    let block = first(blocksCopy);

    if (isNil(block)) {
      return false;
    } else if (
      block.status != "empty" &&
      smallBlock(block) &&
      inCorrectPosition(block, position)
    ) {
      finalPosition += 1;
      blocksCopy.shift();
    } else if (block.status != "empty" && largeBlock(block)) {
      if (finalPosition % 3 != 0) {
        finalPosition += 3 - (finalPosition % 3);
      }
      finalPosition += 3;
      blocksCopy.shift();
    } else {
      finalPosition += 1;
    }
  });

  if (finalPosition % 3 != 0) {
    finalPosition += 3 - (finalPosition % 3);
  }

  // This is because Range does not include the top number
  return range(1, finalPosition + 1);
}
