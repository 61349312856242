import { map, every } from "lodash";

// Helper method to determine if a block is `small`
//
// == Parameters:
// block::
//  The JSON hash of a particular block.
//
// == Returns:
// true or false
//
export const smallBlock = (block) => {
  return block["meta"]["alignMode"] == 0;
};

// Helper method to determine if a block is `large`
//
// == Parameters:
// block::
//  The JSON hash of a particular block.
//
// == Returns:
// true or false
//
export const largeBlock = (block) => {
  return !smallBlock(block);
};

// Helper method to determine if the position of the block is the same as
// the position that is currently being allocated.
//
// == Parameters:
// block::
//  The JSON hash of a particular block.
// position::
// Integer of the currently allocated position.
//
// == Returns:
// true or false
//
export const inCorrectPosition = (block, position) => {
  return block.position == position;
};

// Helper method to create an empty block
//
// == Parameters:
// position::
//  The position to assign to the empty block.
//
// == Returns:
// JSON hash of the empty block.
//
export const emptyBlock = (position) => {
  return {
    position: position,
    status: "empty",
  };
};

// Helper method to determine if a position will be
// in the middle of the story.
//
// == Parameters:
// position::
// An integer representing the position in the story that this block occupies.
//
// == Returns:
// true or false
//
export const inTheMiddle = (position) => {
  return !onTheFarRight(position) && !onTheFarLeft(position);
};

// Helper method to determine if a position will be
// on the far right of the story. Eg a factor of 3 without the position subtraction.
//
// == Parameters:
// position::
// An integer representing the position in the story that this block occupies.
//
// == Returns:
// true or false
//
export const onTheFarRight = (position) => {
  return position % 3 == 0;
};

// Helper method to determine if a position will be
// on the far left of the story. Eg a factor of 3.
//
// == Parameters:
// position::
// An integer representing the position in the story that this block occupies.
// Note that positions on the API start from 1, hence the subtraction.
//
// == Returns:
// true or false
//
export const onTheFarLeft = (position) => {
  return (position - 1) % 3 == 0;
};

// Helper method to determine if a row is empty or not
// A row is considered empty if all of the slots have the status empty
//
// == Parameters:
// row::
// An array of slots that make up that row.
//
// == Returns:
// true or false
//
export const rowEmpty = (row) => {
  return every(row, (slot) => {
    return slot.status == "empty";
  });
};

// Helper method to correct all of the positions, as once the blocks have been sorted
// There positions will not reflect the new indexes where they have been put.
//
// == Parameters:
// blocks::
//  An array of blocks to be corrected.
//
// == Returns:
// An array of blocks with there positions set to their current index + 1.
//
export const correctPositions = (blocks) => {
  return map(blocks, (block, index) => {
    return {
      ...block,
      position: index + 1,
    };
  });
};
